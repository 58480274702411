import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { Observable } from 'rxjs';

import { Coordinate } from 'ol/coordinate';

import {
  forgotPasswordURL,
  myPasswordURL,
  myProfileURL,
  registrationURL,
  resetPasswordURL,
} from '@core/http/endpoints';

import { Password, ProfileResponse } from './';
import { ResetPassword, UserRegistration } from './profile';
import { ProfilePreferences } from './response-profile';

/** Profile service */
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  /** @ignore */
  constructor(private http: HttpClient) {}

  /** Save main settings */
  saveSettings(formValue: FormData): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(myProfileURL, formValue);
  }

  /** Change password */
  savePassword(formValue: Password): Observable<any> {
    return this.http.post<any>(myPasswordURL, formValue);
  }

  registerUser(formValue: UserRegistration): Observable<{ detail: string }> {
    return this.http.post<{ detail: string }>(registrationURL, formValue);
  }

  sendPasswordRecoveryLink(username: string): Observable<any> {
    return this.http.post<any>(forgotPasswordURL, { username });
  }

  resetPassword(formValue: ResetPassword): Observable<any> {
    return this.http.post<any>(resetPasswordURL, formValue);
  }

  confirmPasswordValidation(control: AbstractControl): ValidationErrors {
    let error: ValidationErrors = null;
    const password =
      control.parent?.get('password') || control.parent?.get('new');
    if (password?.touched) {
      error =
        password.value === control?.value ? null : { noPasswordMatch: true };
    }
    return error;
  }

  checkPasswordStrength(control: AbstractControl): ValidationErrors {
    let error: ValidationErrors = null;
    if (control.value) {
      // @see https://regexper.com/?#%5E%28%3F%3D.*%5Ba-z%5D%29%28%3F%3D.*%5BA-Z%5D%29%28%3F%3D.*%5B0-9%5D%29%7C%28%3F%3D.*%5B!%40%23%24%25%5E%26*%5D%29
      const strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[!@#$%^&*])',
      );
      const capitalLetterArray = control.value.match(strongRegex);
      error = capitalLetterArray?.length ? null : { noCapitalLetter: true };
    }
    return error;
  }

  saveUserLang(language: string): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(myProfileURL, { language });
  }

  saveUserPreference(
    preferences: ProfilePreferences,
  ): Observable<ProfileResponse> {
    const modifiedPreferences = preferences.save_session
      ? preferences
      : {
          ...preferences,
          center_x: null,
          center_y: null,
          zoom: null,
          layers: null,
          groups: null,
        };
    return this.http.patch<ProfileResponse>(myProfileURL, modifiedPreferences);
  }

  saveMapSession(
    zoom: number,
    center: Coordinate,
  ): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(myProfileURL, {
      zoom,
      center_x: center[0],
      center_y: center[1],
    });
  }

  saveLayersPosition(
    groups: any[],
    layers: any[],
  ): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(myProfileURL, {
      preferences: { layers, groups },
    });
  }

  saveUserProjection(projection: string): Observable<ProfileResponse> {
    return this.http.patch<ProfileResponse>(myProfileURL, { projection });
  }
}
