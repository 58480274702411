import { createAction, props } from '@ngrx/store';

import { InlineField } from '@modules/map-panel/object-panel/object-panel.actions';
import { SearchQuery } from '@modules/spatial-search/spatial-search';

import { ModelNormalize, ObjectResponse } from './models';

export const actionTableGet = createAction(
  '[Data/API] 💬 Load table',
  props<{
    contentType: number;
    searchQuery?: SearchQuery;
    inlineField?: InlineField;
  }>(),
);

export const actionGetOptions = createAction(
  '[Data/API] ℹ️ Get model options',
  props<{
    contentType: number;
  }>(),
);

export const actionTableGetOptions = createAction(
  '[Data/API] ℹ️ Get table options',
  props<{
    contentType: number;
    searchQuery?: SearchQuery;
    inlineField?: InlineField;
  }>(),
);
export const actionTableGetOptionsSuccess = createAction(
  '[Data/API] ℹ️ Get table options ✅',
  props<{ contentType: number; model: ModelNormalize }>(),
);

export const actionTableGetData = createAction(
  '[Data/API] 📊 Get table data',
  props<{
    contentType: number;
    model: ModelNormalize;
    searchQuery?: SearchQuery;
    inlineField?: InlineField;
  }>(),
);
export const actionTableGetDataSuccess = createAction(
  '[Data/API] 📊 Get table data ✅',
  props<{
    contentType: number;
    rows: ObjectResponse[];
    filterCount?: number;
    objectCount?: number;
  }>(),
);
export const actionInlineTableGetDataSuccess = createAction(
  '[Data/API] 📊 Get inline table data ✅',
  props<{
    contentType: number;
    rows: ObjectResponse[];
    objectCount?: number;
  }>(),
);

export const actionTableGetGeometries = createAction(
  '[Data/API] 📍 Get table geometries',
  props<{ contentType: number; hasGeom: boolean }>(),
);
export const actionTableGetGeometriesSuccess = createAction(
  '[Data/API] 📍 Get table geometries ✅',
  props<{ contentType: number; rows: ObjectResponse[] }>(),
);

export const actionTableGetSuccess = createAction(
  '[Data/API] ✅ Load table success',
  props<{ contentType: number; model?: ModelNormalize }>(),
);

export const actionTableLoadMoreData = createAction(
  '[Data/API] 📊 Get more table data',
  props<{
    contentType: number;
    offset: number;
    orderParam?: string;
    filterParam?: string;
    rows?: ObjectResponse[];
  }>(),
);

export const actionTableOrdering = createAction(
  '[Data/API] 🔀 Reorder table',
  props<{
    contentType: number;
    offset?: number;
    orderParam: string;
    filterParam?: string;
    rows?: ObjectResponse[];
  }>(),
);

export const actionTableSearching = createAction(
  '[Data/API] 🔎 Search in table',
  props<{ contentType: number }>(),
);

export const actionTableFiltering = createAction(
  '[Data/API] 🔤 Filter in table',
  props<{
    contentType: number;
    offset?: number;
    orderParam?: string;
    rows?: ObjectResponse[];
  }>(),
);
