import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { Feature, Map } from 'ol';
import WKT from 'ol/format/WKT';
import { Geometry } from 'ol/geom';
import { Draw, Modify, Snap } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { SimplemapService } from '../simplemap.service';

/**
 * Draw component: enable drawing for simple map
 * output geometry in WKT format.
 *
 * @example
 * <gogis-simple-map id='map' class="flex w-64 h-64">
 * <gogis-simple-map-draw (geometryString)="geometryChanged($event)" [Type]="'LineString'"></gogis-simple-map-draw>
 * </gogis-simple-map>
 */
@Component({
  selector: 'gogis-simple-map-draw',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawComponent implements OnInit, OnChanges {
  /* WKT geometry string from drawed object */
  @Output() geometryString? = new EventEmitter<string>();

  /* Draw type: "Polygon" "Point" "LineString"*/
  @Input() type?: string;

  @Input() initialGeometry?: string;

  private drawType: any;

  private map: Map;

  private draw: Draw;

  private modify: Modify;

  private wktFormat = new WKT();

  private source = new VectorSource({
    wrapX: false,
  });

  private vector = new VectorLayer({
    source: this.source,
  });

  private isInitialized = false;
  constructor(private simplemapService: SimplemapService) {}

  ngOnInit(): void {
    this.map = this.simplemapService.map;

    if (this.initialGeometry) {
      this.source.addFeature(
        this.wktFormat.readFeature(this.initialGeometry, {
          dataProjection: 'EPSG:3059',
        }),
      );
    }

    this.map.addLayer(this.vector);

    this.defineDrawType();
    this.addDrawInteraction();
    this.addModifyInteraction();
    this.defineDrawEvents();
    this.defineModifyEvents();

    this.isInitialized = true;
  }

  ngOnChanges(): void {
    if (this.isInitialized) {
      this.defineDrawType();
      this.map.removeInteraction(this.draw);
      this.addDrawInteraction();
      this.addModifyInteraction();
      this.defineDrawEvents();
      this.defineModifyEvents();
    }
  }

  defineDrawEvents(): void {
    this.draw.on('drawend', (evt) => {
      this.draw.finishDrawing();
      this.outputGeometryString(evt.feature);
    });

    this.draw.on('drawstart', () => {
      this.restartDraw();
    });
  }

  defineModifyEvents(): void {
    this.modify.on('modifyend', (evt) => {
      evt.features.forEach((feature: Feature<Geometry>) => {
        this.outputGeometryString(feature);
      });
    });
  }

  defineDrawType(): void {
    if (!this.type) {
      this.type = 'Polygon';
    } else {
      switch (this.type) {
        case 'Polygon':
          this.drawType = 'Polygon';
          break;
        case 'Point':
          this.drawType = 'Point';
          break;
        case 'LineString':
          this.drawType = 'LineString';
          break;
        default:
          this.drawType = 'Polygon';
      }
    }
  }

  addDrawInteraction(): void {
    this.draw = new Draw({
      source: this.source,
      type: this.drawType,
    });
    this.map.addInteraction(this.draw);
  }

  outputGeometryString(feature: Feature<Geometry>): void {
    this.geometryString.emit(
      this.wktFormat.writeGeometry(feature.getGeometry()),
    );
  }

  // goes through all VectorSource features and deletes them
  restartDraw(): void {
    this.map.getLayers().forEach((layer) => {
      layer.getLayersArray().forEach((arrayLayer) => {
        if (arrayLayer.getSource() instanceof VectorSource) {
          const source = arrayLayer.getSource() as VectorSource<
            Feature<Geometry>
          >;
          source.getFeatures().forEach((feature) => {
            source.removeFeature(feature);
          });
        }
      });
    });
  }

  addModifyInteraction(): void {
    this.modify = new Modify({ source: this.source });
    this.map.addInteraction(this.modify);
    this.map.addInteraction(new Snap({ source: this.source }));
  }
}
