import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { actionTokenExpire } from '@core/auth';

import { deleteCookieURL } from './endpoints';

interface GogisError {
  statut: number;
  messages: GogisErrorMessage[];
}

interface GogisErrorMessage {
  field?: string;
  message: string;
  code: string;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private http: HttpClient,
    private store: Store,
    private toastrService: ToastrService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          ((request.url.includes('refresh') && error.status === 400) ||
            error.status === 401) &&
          ((error.error as GogisError).messages[0].code === 'token_not_valid' ||
            (error.error as GogisError).messages[0].code === 'invalid' ||
            (error.error as GogisError).messages[0].code ===
              'token_not_provided' ||
            (error.error as GogisError).messages[0].code === 'token_expired' ||
            (error.error as GogisError).messages[0].code ===
              'authentication_failed')
        ) {
          this.store.dispatch(actionTokenExpire());

          return this.http.post(deleteCookieURL, {}).pipe(
            switchMap(() => {
              request = request.clone({
                withCredentials: false,
              });

              return next.handle(request);
            }),
          );
        }

        let formatedError = '';

        (error.error as GogisError)?.messages?.forEach((message) => {
          formatedError += message.field
            ? `${message.field}: ${message.message} `
            : `${message.message} `;
        });

        // Check with backend to format the error
        // see. https://projekti.sungis.lv/issues/3373 & https://projekti.sungis.lv/issues/3605
        formatedError = formatedError || 'Error not formated.';

        if (!request.url.includes('refresh')) {
          // Do not show the toastr in case of first refresh (to check if user is login)
          this.toastrService.error(formatedError);
        }

        return throwError(formatedError);
      }),
    );
  }
}
