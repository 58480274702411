<h2 mat-dialog-title>{{ "PROFILE.AGREEMENT.AGREEMENT_TITLE" | translate }}</h2>
<mat-dialog-content>
  {{ "PROFILE.AGREEMENT.AGREEMENT_CONTENT" | translate
  }}<a href="https://gis.sungis.lv/doc/privatums/index.html" target="_blank" class="gogis-simple-link lowercase">
    {{ "PROFILE.AGREEMENT.TERMS&CONDITIONS" | translate }}</a
  >
</mat-dialog-content>
<mat-dialog-actions align="end">
  <gogis-button color="grey" class="mr-4" label="{{ 'PROFILE.AGREEMENT.REFUSE' | translate }}" [mat-dialog-close]="true" (click)="refuse()" />
  <gogis-button label="{{ 'PROFILE.AGREEMENT.ACCEPT' | translate }}" [mat-dialog-close]="true" (click)="accept()" />
</mat-dialog-actions>
