import { Injectable } from '@angular/core';

/** @ignore */
const APP_PREFIX = 'GOGIS-';

/**
 * Service to initialize AppState from local storage,
 * set/get/remove item from local storage.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /** @ignore */
  static loadInitialState(): Record<string, unknown> {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0
                  ? token
                  : token.charAt(0).toUpperCase() + token.slice(1),
              )
              .join(''),
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  /**
   * Set item to localstorage
   *
   * @param key use one of the key from /core/local-storage/keys.ts
   * @param value value to store
   */
  setItem(key: string, value: any): void {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  /**
   * Get item from localstorage
   *
   * @param key use one of the key from /core/local-storage/keys.ts
   */
  getItem(key: string): string {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
  }

  /**
   * Remove item from localstorage
   *
   * @param key use one of the key from /core/local-storage/keys.ts
   */
  removeItem(key: string): void {
    localStorage.removeItem(`${APP_PREFIX}${key}`);
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  testLocalStorage(): void {
    const testValue = 'testValue';
    const testKey = 'testKey';
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    const retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }
}
