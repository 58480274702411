<h2 mat-dialog-title>{{ "PROFILE.PASSWORD_RESET.TITLE" | translate }}</h2>
<form data-cy="reset-password-form">
  <mat-dialog-content>
    <label class="mt-2 font-bold" for="username">{{ "PROFILE.PASSWORD_RESET.USERNAME" | translate }}</label>
    <span class="flex flex-col">
      <input placeholder="Username" name="username" id="username" type="text" required [formControl]="username" />
      <small class="my-2 ml-1 mr-4">{{ "PROFILE.PASSWORD_RESET.DESCRIPTION" | translate }}</small>
      <gogis-errors-input *ngIf="username.touched" [field]="{ label: 'PROFILE.PASSWORD_RESET.USERNAME' | translate }" [errors]="username.errors" />
    </span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <gogis-button color="grey" mat-dialog-close class="ml-4" label="{{ 'GLOBAL.CANCEL' | translate }}" />
    <gogis-button
      type="submit"
      color="green"
      class="ml-auto mr-4"
      data-cy="reset-form-button"
      label="{{ 'GLOBAL.CONFIRM' | translate }}"
      (click)="sendPasswordRecoveryLink()"
    />
  </mat-dialog-actions>
</form>
