import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BarButtonComponent } from './bar-button/bar-button.component';
import { DrawComponent } from './draw/draw.component';
import { GeometryComponent } from './geometry/geometry.component';
import { LayerComponent } from './layer/layer.component';
import { SimplemapComponent } from './simplemap.component';

@NgModule({
  declarations: [
    SimplemapComponent,
    LayerComponent,
    GeometryComponent,
    DrawComponent,
    BarButtonComponent,
  ],
  exports: [
    SimplemapComponent,
    LayerComponent,
    GeometryComponent,
    DrawComponent,
    BarButtonComponent,
  ],
  imports: [CommonModule],
})
export class SimplemapModule {}
