import {
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { AccordionDirective } from './accordion.directive';

/**
 * Add the component that will expand or collapse all the `<accordion-panel>`
 *
 * @example
 * <gogis-accordion>
 *  <gogis-accordion-toggle></gogis-accordion-toggle>
 * </gogis-accordion>
 */
@Component({
  selector: 'gogis-accordion-toggle',
  template: `
    <button
      *ngIf="translationResp"
      data-cy="accordion-toggle"
      class="my-2 ml-6 rounded-md p-1 text-sm text-gis-grey-700 underline hover:text-gis-grey-500 focus:outline-none"
    >
      {{
        (accordion.expandState$ | async)
          ? translationResp['GLOBAL.COLLAPSE']
          : translationResp['GLOBAL.EXPAND']
      }}
    </button>
  `,
})
export class AccordionToggleComponent implements OnInit {
  @Output() toggleValue = new EventEmitter<boolean>();
  /** Response from translate service. */
  translationResp: string[];

  /** subject to unsubscribe on destroy. */
  private destroyRef = inject(DestroyRef);

  /** @ignore */
  constructor(
    public accordion: AccordionDirective,
    private translateService: TranslateService,
  ) {}

  /**
   *  listen click on element with the directive
   */
  @HostListener('click')
  onClick(): void {
    this.accordion.expandState$.next(!this.accordion.expandState$.value);
    this.accordion.expandState$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        this.accordion.toggleClick.emit(state);
        this.toggleValue.emit(state);
      });
  }

  /** @ignore */
  ngOnInit(): void {
    this.translateService
      .stream(['GLOBAL.EXPAND', 'GLOBAL.COLLAPSE'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp: string[]) => {
        this.translationResp = resp;
      });
  }
}
