import { createAction, props } from '@ngrx/store';

import { ProfileResponse } from '@modules/profile';

export const actionChangeLanguage = createAction(
  '[Header] Change Language',
  props<{ payload: string }>(),
);

export const actionChangeLanguageSuccess = createAction(
  '[Header] Change Language Success',
  props<{ response: ProfileResponse }>(),
);

export const actionChangeLanguageFailure = createAction(
  '[Header] Change Language',
  props<{ error: string }>(),
);
