import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { selectIsAuthenticated } from '@core/auth';
import { ProfileService } from '@modules/profile/profile.service';

import {
  actionChangeProjection,
  actionChangeProjectionFailure,
  actionChangeProjectionSuccess,
} from './footer.actions';

@Injectable()
export class FooterEffects {
  saveUserProjectionPreference$ = createEffect(() =>
    this.action$.pipe(
      ofType(actionChangeProjection),
      concatMap((action) =>
        of(action).pipe(
          concatLatestFrom(() => this.store.select(selectIsAuthenticated)),
        ),
      ),
      switchMap(([action, isAuthenticated]) =>
        isAuthenticated
          ? this.profileService
              .saveUserProjection(action.projection.replace(/([^0-9])+/g, ''))
              .pipe(
                map((response) => actionChangeProjectionSuccess({ response })),
                catchError((error) =>
                  of(actionChangeProjectionFailure({ error })),
                ),
              )
          : EMPTY,
      ),
    ),
  );

  constructor(
    private action$: Actions,
    private profileService: ProfileService,
    private store: Store,
  ) {}
}
