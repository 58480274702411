@if (errors) {
  <div class="break-words text-sm text-gis-red-500" [attr.data-cy]="'error-input-' + field.label.replaceAll(' ', '')">
    <div>
      @if (errors.required) {
        <p>{{ "ERROR_INPUT.IS_REQUIRED" | translate: { input: field.label } }}</p>
      }
      @if (errors.min) {
        <p>{{ "ERROR_INPUT.MINIMUM" | translate: { input: field.label, minimum: errors.min.min } }}</p>
      }
      @if (errors.max) {
        <p>{{ "ERROR_INPUT.MAXIMUM" | translate: { input: field.label, maximum: errors.max.max } }}</p>
      }
      @if (errors.requiredtrue) {
        <p>{{ "ERROR_INPUT.REQUIREDTRUE" | translate: { input: field.label } }}</p>
      }
      @if (errors.email) {
        <p>{{ "ERROR_INPUT.EMAIL" | translate: { input: field.label } }}</p>
      }
      @if (errors.minlength) {
        <p>{{ "ERROR_INPUT.MINLENGTH" | translate: { input: field.label, minlength: errors.minlength.requiredLength } }}</p>
      }
      @if (errors.maxlength) {
        <p>{{ "ERROR_INPUT.MAXLENGTH" | translate: { input: field.label, maxlength: errors.maxlength.requiredLength } }}</p>
      }
      @if (errors.pattern) {
        <p>{{ "ERROR_INPUT.PATTERN" | translate: { input: field.label } }}</p>
      }
      @if (errors.invalidInteger) {
        <p>{{ "ERROR_INPUT.INVALIDINTEGER" | translate }}</p>
      }
      @if (errors.invalidIPAddress) {
        <p>{{ "ERROR_INPUT.INVALIDIPADRESS" | translate }}</p>
      }
      @if (errors.invalidUUID) {
        <p>{{ "ERROR_INPUT.INVALIDUUID" | translate }}</p>
      }
      @if (errors.noPasswordMatch) {
        <p>{{ "ERROR_INPUT.NOPASSWORDMATCH" | translate }}</p>
      }
      @if (errors.noCapitalLetter) {
        <p>{{ "ERROR_INPUT.NOCAPITALLETTR" | translate }}</p>
      }
      @if (
        !errors.required &&
        !errors.min &&
        !errors.max &&
        !errors.requiredtrue &&
        !errors.email &&
        !errors.minlength &&
        !errors.maxlength &&
        !errors.pattern &&
        !errors.invalidInteger &&
        !errors.invalidIPAddress &&
        !errors.invalidUUID &&
        !errors.noPasswordMatch &&
        !errors.noCapitalLetter
      ) {
        <p>Error not defined</p>
      }
    </div>
  </div>
}
