import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, withLatestFrom } from 'rxjs';

export interface MapPanelHistory {
  [key: string]: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class MapPanelHistoryService {
  history: MapPanelHistory[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.routerListener();
  }

  routerListener() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        withLatestFrom(this.route.queryParams),
      )
      .subscribe(([e, params]) => {
        this.history.push({ url: (e as NavigationEnd).url, ...params });
      });
  }
}
