import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { SearchQuery } from '../spatial-search';
import { SearchResultResponse } from '../spatial-search';

export const actionSearch = createAction(
  '[Search] 💬 Searching',
  props<{ searchQuery: SearchQuery }>(),
);

export const actionSearchSuccess = createAction(
  '[Search/API] ✅ Searching Success',
  props<{ results: SearchResultResponse[] }>(),
);

export const actionSearchFailure = createAction(
  '[Search/API] ❌ Searching Failure',
  props<{ error: string }>(),
);

export const actionCancelSearch = createAction('[Search] 🛑 Search Cancelled');

export const actionClearResults = createAction('[Search] 🗑️ Clear Results');

export const actionChangeColor = createAction(
  '[Search] 🎨 Change color',
  props<{ update: Update<SearchResultResponse> }>(),
);

export const actionRemoveFromResult = createAction(
  '[Search] 📤 remove table from results',
  props<{ id: number }>(),
);

export const actionTableGetWithResults = createAction(
  '[Search] 📖 Open table',
  props<{ contentType: number; searchQuery: SearchQuery }>(),
);
export const actionOpenObjectFromSearch = createAction(
  '[Search] 📑 Open object detail',
  props<{ objectId: string; modelId: number }>(),
);

export const actionToggleModel = createAction(
  '[Search] ↔️ Toggle model',
  props<{ update: Update<SearchResultResponse> }>(),
);

export const actionToggleAllModel = createAction(
  '[Search] ↔️ Toggle all models',
  props<{ updates: Update<SearchResultResponse>[] }>(),
);
