import { Directive, ElementRef, HostListener } from '@angular/core';

import { DropdownComponent } from './dropdown.component';

/**
 * dropdown toggle directive:
 * add the directive that will toggle the popover
 *
 * @example
 * <gogis-dropdown>
 *  <button gogisDropdownToggle></button>
 * </gogis-dropdown>
 */
@Directive({
  selector: '[gogisDropdownToggle]',
})
export class DropdownToggleDirective {
  /**
   * Inject dependencies inside constructor
   *
   * @param element  access to native element
   * @param dropdown  access to dropdown component methods
   */
  constructor(
    private element: ElementRef,
    private dropdown: DropdownComponent,
  ) {}

  /**
   *  listen click on element with the directive
   */
  @HostListener('click')
  onClick(): void {
    this.dropdown.toggleClick$.emit();
  }

  /**
   *  listen click on all the page
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any): void {
    if (
      event.button !== 2 &&
      (!this.element.nativeElement.parentElement.contains(event.target) ||
        (!this.element.nativeElement.contains(event.target) &&
          this.dropdown.closeOnClickInside === true))
    ) {
      this.dropdown.isOpenChange$.emit(false);
    }
  }

  /**
   *  listen escape button being press
   */
  @HostListener('keyup.escape')
  onEsc(): void {
    this.dropdown.isOpenChange$.emit(false);
  }
}
