import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from '@modules/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'map',
    pathMatch: 'full',
  },
  {
    path: 'map',
    loadChildren: () =>
      import('./modules/map/map.module').then((m) => m.MapModule),
  },
  {
    path: 'map/:compositionName',
    loadChildren: () =>
      import('./modules/map/map.module').then((m) => m.MapModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./modules/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'playground',
    data: { title: 'GLOBAL.MVT' },
    loadComponent: () =>
      import('./modules/playground/playground.component').then(
        (m) => m.PlaygroundComponent,
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'my-tasks',
    loadChildren: () =>
      import('./modules/my-task/my-task.module').then((m) => m.MyTaskModule),
  },
  {
    path: 'vote',
    loadChildren: () =>
      import('./modules/vote/vote.module').then((m) => m.VoteModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
