import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { IssueResponse } from '@core/api/models';

export const actionGetIssues = createAction(
  '[Issue List] 💬 Load issues',
  props<{ ordering?: string; filter?: any[] }>(),
);

export const actionGetIssuesSuccess = createAction(
  '[Issue/API] ✅ Load issues Success',
  props<{ payload: IssueResponse[] }>(),
);

export const actionAddIssue = createAction(
  '[Issue Add/Edit Modal] ➕ Add issue',
  props<{
    data: IssueResponse;
    objectID?: string;
    tempIssue?: IssueResponse;
  }>(),
);
export const actionAddIssueSuccess = createAction(
  '[Form] ✅ Add issue Success',
  props<{
    entity: IssueResponse;
    payload: {
      issue: IssueResponse;
      objectID: string;
      modelID: number;
    };
  }>(),
);

export const actionEditIssue = createAction(
  '[Issue Add/Edit Modal] 📨 Edit issue',
  props<{ data: IssueResponse; issueId: string }>(),
);
export const actionEditIssueSuccess = createAction(
  '[Form] ✅ Edit issue Success',
  props<{ update: Update<IssueResponse> }>(),
);

export const actionDeleteIssue = createAction(
  '[Form] 🗑 Delete issue',
  props<{ issueId: string }>(),
);
export const actionDeleteIssueSuccess = createAction(
  '[Form] ✅ Delete issue Success',
  props<{ id: string }>(),
);

export const actionGetTasks = createAction('[Task List] 💬 Load tasks');

export const actionGetTasksSuccess = createAction(
  '[Task/API] ✅ Load tasks Success',
  props<{ payload: IssueResponse[] }>(),
);
export const actionAddTask = createAction(
  '[Task List] ➕ add new task',
  props<{ values: any }>(),
);
export const actionAddTasksSuccess = createAction(
  '[Task/API] ✅ add tasks Success',
  props<{ payload: any }>(),
);

export const actionUpdateTask = createAction(
  '[Task List] 📨 update task',
  props<{ taskId: string; issueId: string; body: any }>(),
);
export const actionUpdateTaskSuccess = createAction(
  '[Task List] ✅ update task Success',
  props<{ payload: any; issueId: string }>(),
);

export const actionUpdateMyTask = createAction(
  '[Task List] 📨 update my task',
  props<{ taskId: string; issueId: string; body: any }>(),
);
export const actionUpdateMyTaskSuccess = createAction(
  '[Task List] ✅ update my task Success',
  props<{ update: Update<any> }>(),
);

export const actionDeleteTask = createAction(
  '[Task List] 🗑 Delete task',
  props<{ taskId: string; issueId: string }>(),
);
export const actionDeleteTaskSuccess = createAction(
  '[Task List] ✅ Delete task Success',
  props<{ taskId: string; issueId: string }>(),
);

export const actionSolveIssue = createAction(
  '[Issue] Solve issue',
  props<{ issueId: string; data: boolean }>(),
);
export const actionSolveIssueSuccess = createAction(
  '[Issue] ✅ Solve issue Success',
  props<{ update: Update<IssueResponse> }>(),
);

export const actionGetPersonalTodoTasks = createAction(
  '[Task List] Get personal todo tasks',
);

export const actionGetPersonalTodoTasksSuccess = createAction(
  '[API/Task] ✅ Get personal todo tasks',
  props<{ payload: any[] }>(),
);

export const actionGetPersonalDoneTasks = createAction(
  '[Task List] Get personal done tasks',
);

export const actionGetPersonalDoneTasksSuccess = createAction(
  '[API/Task] ✅ Get personal done tasks',
  props<{ payload: any[] }>(),
);
