import { createReducer, on } from '@ngrx/store';

import { actionCloseSidebar, actionToggleTool } from './sidebar.actions';

export const sidebarFeatureKey = 'sidebar';

export interface SidebarState {
  selectedTool: string;
}

const initialState: SidebarState = {
  selectedTool: null,
};

export const sidebarReducer = createReducer(
  initialState,
  on(
    actionToggleTool,
    (state, { payload }): SidebarState => ({
      ...state,
      selectedTool: payload === state.selectedTool ? null : payload,
    }),
  ),
  on(
    actionCloseSidebar,
    (state): SidebarState => ({
      ...state,
      selectedTool: null,
    }),
  ),
);
