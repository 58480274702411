<h2 mat-dialog-title>{{ "PROFILE.REGISTRATION.TITLE" | translate }}</h2>
<form class="flex min-w-[20rem] flex-col pb-4" data-cy="registration-form" [formGroup]="registrationForm">
  <mat-dialog-content>
    <div class="grid grid-cols-6 gap-6">
      <!-- <div *ngFor="let field of registrationFields" class="col-span-6">
        <label class="mt-2 font-bold" for="username">
          {{ field.label | translate }}
        </label>
        <ng-template gogisDynamiField [form]="registrationForm" [field]="field" [data]="field.default"> </ng-template>
      </div> -->
      <div class="col-span-6">
        <label class="mt-2 font-bold" for="username">
          {{ "PROFILE.REGISTRATION.USERNAME" | translate }}
        </label>
        <input formControlName="username" placeholder="JānisB" type="text" name="username" id="username" required />
        <gogis-errors-input
          *ngIf="getControl('username').touched"
          [field]="{ label: 'PROFILE.REGISTRATION.USERNAME' | translate }"
          [errors]="getControl('username').errors"
        />
      </div>

      <div class="col-span-6">
        <label class="mt-2 font-bold" for="email">
          {{ "PROFILE.REGISTRATION.EMAIL" | translate }}
        </label>
        <input formControlName="email" placeholder="jānis.berzins@email.lv" type="text" name="email" id="email" required />
        <gogis-errors-input
          *ngIf="getControl('email').touched"
          [field]="{ label: 'PROFILE.REGISTRATION.EMAIL' | translate }"
          [errors]="getControl('email').errors"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <label class="mt-2 font-bold" for="firstName">
          {{ "PROFILE.REGISTRATION.FIRST_NAME" | translate }}
        </label>
        <input formControlName="firstName" placeholder="Jānis" type="text" id="firstName" required />
        <gogis-errors-input
          *ngIf="getControl('firstName').touched"
          [field]="{ label: 'PROFILE.REGISTRATION.FIRST_NAME' | translate }"
          [errors]="getControl('firstName').errors"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <label class="mt-2 font-bold" [for]="'lastName'">
          {{ "PROFILE.REGISTRATION.LAST_NAME" | translate }}
        </label>
        <input formControlName="lastName" placeholder="Berzins" type="text" id="lastName" required />
        <gogis-errors-input
          *ngIf="getControl('lastName').touched"
          [field]="{ label: 'PROFILE.REGISTRATION.LAST_NAME' | translate }"
          [errors]="getControl('lastName').errors"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <label class="mt-2 font-bold" [for]="'password'">
          {{ "PROFILE.REGISTRATION.PASSWORD" | translate }}
        </label>
        <input formControlName="password" placeholder="**********" type="password" id="password" required />
        <gogis-errors-input
          *ngIf="getControl('password').touched"
          [field]="{ label: 'PROFILE.REGISTRATION.PASSWORD' | translate }"
          [errors]="getControl('password').errors"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <label class="mt-2 font-bold" [for]="'confirmPassword'">
          {{ "PROFILE.REGISTRATION.CONFIRM_PASSWORD" | translate }}
        </label>
        <input formControlName="confirmPassword" placeholder="**********" type="password" id="confirmPassword" required />
        <gogis-errors-input
          *ngIf="getControl('confirmPassword').touched"
          [field]="{ label: 'PROFILE.REGISTRATION.CONFIRM_PASSWORD' | translate }"
          [errors]="getControl('confirmPassword').errors"
        />
      </div>

      <div class="col-span-6">
        <label class="mt-2 font-bold" for="userTypeSelection">
          {{ "PROFILE.REGISTRATION.USER_TYPE" | translate }}
        </label>
        <ng-select
          formControlName="userTypeSelection"
          class="mt-2"
          appendTo="body"
          bindLabel="name"
          bindValue="value"
          id="userTypeSelection"
          required
          [clearable]="false"
          [items]="userTypes"
        />
      </div>

      <div class="col-span-6">
        <input type="checkbox" name="terms" id="terms" formControlName="termsAndConditions" required />
        <label for="terms" class="ml-1"
          >I have read and accept the<a href="https://gis.sungis.lv/doc/privatums/index.html" target="_blank" class="gogis-simple-link"
            >Terms of Service and Privacy Policy</a
          ></label
        >
        <gogis-errors-input
          *ngIf="getControl('termsAndConditions').touched"
          [field]="{ label: 'PROFILE.REGISTRATION.TERMS_AND_CONDITIONS' | translate }"
          [errors]="getControl('termsAndConditions').errors"
        />
      </div>
    </div>

    <!-- <p>Sign up for SunGIS update emails</p> -->
  </mat-dialog-content>
  <mat-dialog-actions>
    <gogis-button color="grey" mat-dialog-close class="ml-4" label="{{ 'GLOBAL.CANCEL' | translate }}" />
    <gogis-button type="submit" label="Register" color="green" class="ml-auto mr-4" (click)="sendRegistration()" />
  </mat-dialog-actions>
</form>
