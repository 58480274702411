import { createAction, props } from '@ngrx/store';

import { ProfileResponse } from '@modules/profile';

import { AuthCredentials, AuthResponse } from '../auth';

export const actionCheckInitialLogin = createAction(
  '[App init] 💬 Check initial Login',
);
export const actionTokenExpire = createAction('[Event] 💬 Token expire');

export const actionAuthLogin = createAction(
  '[Header login] 🔐 Login',
  props<{ credentials: AuthCredentials }>(),
);

export const actionAuthLogout = createAction('[Header login] 🔒 Logout');

export const actionAuthLoginSuccess = createAction(
  '[Auth/API] ✅ Login Success',
  props<{ response: AuthResponse }>(),
);

export const actionAuthLoginFailure = createAction(
  '[Auth/API] ❌ Login Failure',
  props<{ error: string }>(),
);

export const actionCheckLoginSuccess = createAction(
  '[Auth/API] ✅ Check Login Success',
  props<{ response: AuthResponse }>(),
);

export const actionCheckLoginFailure = createAction(
  '[Auth/API] ❌ Check Login Failure',
  props<{ error: string }>(),
);

export const actionRefreshToken = createAction(
  '[Refresh] 🔄 refresh JWT token',
);

export const actionRefreshTokenSuccess = createAction(
  '[Refresh/API] ✅ refresh JWT token Success',
  props<{ response: AuthResponse }>(),
);

export const actionRefreshTokenFailure = createAction(
  '[Refresh/API] ❌ refresh JWT token Failure',
  props<{ error: string }>(),
);

export const actionGetUser = createAction('[Login] 💬 Get user profile');

export const actionGetUserSuccess = createAction(
  '[Profile/API] ✅ Get user Success',
  props<{ response: ProfileResponse }>(),
);

export const actionGetUserFailure = createAction(
  '[Profile/API] ❌ Get user Failure',
  props<{ error: string }>(),
);
