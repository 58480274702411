export interface Password {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export interface UserRegistration {
  username: string;
  password: string;
  password_confirm: string;
  first_name: string;
  last_name: string;
  email: string;
  tips: 'A' | 'J' | 'F'; // User type - 'A' : 'Administrative person', 'F' : 'Physical person', 'J' : 'Juridical person'
  privatuma_politika: boolean;
}
export interface ResetPassword {
  token: string;
  new: string;
  confirm: string;
}
