import { NgModule } from '@angular/core';

import { DropdownComponent } from './dropdown.component';
import { DropdownContainerComponent } from './dropdown-container.component';
import { DropdownToggleDirective } from './dropdown-toggle.directive';

@NgModule({
  declarations: [
    DropdownComponent,
    DropdownToggleDirective,
    DropdownContainerComponent,
  ],
  exports: [
    DropdownComponent,
    DropdownToggleDirective,
    DropdownContainerComponent,
  ],
  imports: [],
})
export class DropdownModule {}
