import { createAction, props } from '@ngrx/store';
import { TableColumnProp } from '@swimlane/ngx-datatable';

import { ReportData } from '@core/api/models/response-report';
import { SearchQuery } from '@modules/spatial-search/spatial-search';

import { InlineField } from '../object-panel/object-panel.actions';

export const actionTableGet = createAction(
  '[Catalog list] 📖 Open table',
  props<{
    contentType: number;
    searchQuery?: SearchQuery;
    inlineField?: InlineField;
    objectId?: number;
  }>(),
);

export const actionTableClose = createAction('[Map panel] ⬅️ Close table');

export const actionAddNewObject = createAction('[Table] ✏️ Add new object');

export const actionUpdateFilter = createAction(
  '[Table] 🎚🔡 Update filter type/value',
  props<{
    modelId: number;
    columnName: TableColumnProp;
    filter: string;
    value: string;
  }>(),
);

export const actionOpenObjectFromTable = createAction(
  '[Table] 📄 View object detail',
  props<{ objectId: string; modelId: number }>(),
);
export const actionOpenObjectEditing = createAction(
  '[Table] 📝 Edit object detail',
  props<{ objectId: string; modelId: number }>(),
);

export const actionOpenReport = createAction(
  '[Table Panel] 📊 Open report',
  props<{ url: string }>(),
);
export const actionOpenReportSuccess = createAction(
  '[Reports/API] ✅ Open report success',
  props<{ payload: ReportData; modelId: number }>(),
);
