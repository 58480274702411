import { IFilterComp, IFilterParams } from 'ag-grid-community';

export class BooleanFilter implements IFilterComp {
  eGui!: HTMLDivElement;
  rbAll: any;
  rbTrue: any;
  rbFalse: any;
  resetFilterButton: any;
  filterActive!: boolean | null;
  filterChangedCallback!: (additionalEventAttributes?: any) => void;

  init(params: IFilterParams) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<form class="ag-filter-wrapper ag-focus-managed">
              <div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
                <label>
                    <input type="radio" name="booleanFilter" checked="true" id="rbAll" filter-checkbox="true"/> All
                </label>
                <label>
                    <input type="radio" name="booleanFilter" id="rbTrue" filter-checkbox="true"/> True
                </label>
                <label>
                    <input type="radio" name="booleanFilter" id="rbFalse" filter-checkbox="true"/> False
                </label>
              </div>
              <div class="ag-filter-apply-panel">
              <button type="button" ref="resetFilterButton" id="resetFilter" class="ag-button ag-standard-button ag-filter-apply-panel-button">Reset
                </button></div>
            </form>`;
    this.rbAll = this.eGui.querySelector('#rbAll');
    this.rbTrue = this.eGui.querySelector('#rbTrue');
    this.rbFalse = this.eGui.querySelector('#rbFalse');
    this.resetFilterButton = this.eGui.querySelector('#resetFilter');
    this.rbAll.addEventListener('change', this.onRbChanged.bind(this));
    this.rbTrue.addEventListener('change', this.onRbChanged.bind(this));
    this.rbFalse.addEventListener('change', this.onRbChanged.bind(this));
    this.resetFilterButton.addEventListener('click', () => {
      this.rbAll.checked = true;
      this.rbTrue.checked = false;
      this.rbFalse.checked = false;
      this.filterActive = false;
      this.filterChangedCallback();
    });
    this.filterActive = null;
    this.filterChangedCallback = params.filterChangedCallback;
  }

  onRbChanged() {
    this.filterActive = this.rbTrue.checked || this.rbFalse.checked;
    this.filterChangedCallback();
  }

  getGui() {
    return this.eGui;
  }

  isFilterActive() {
    return this.filterActive;
  }

  doesFilterPass() {
    return true;
  }

  // this example isn't using getModel() and setModel(),
  // so safe to just leave these empty. don't do this in your code!!!
  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return {
      filterType: 'boolean',
      filter: this.rbTrue.checked ? 'true' : 'false',
      type: 'equals',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setModel() {}
}
