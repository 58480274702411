import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ObjectResponse } from './api/models';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private http: HttpClient,
  ) {}

  isMobile(): Observable<BreakpointState> {
    return this.breakpointObserver.observe(Breakpoints.Handset);
  }

  convertJSONToCSV(arrayOfJson: ObjectResponse[]): string {
    const replacer = (_key, value) => (value === null ? '' : value);
    const header = Object.keys(arrayOfJson[0]);
    const csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    );
    csv.unshift(header.join(','));

    return csv.join('\r\n');
  }

  createSVG(boxWidth: string, boxHeight: string, paths: string[]): HTMLElement {
    const xmlns = 'http://www.w3.org/2000/svg';

    const svgElem = document.createElementNS(xmlns, 'svg');
    svgElem.setAttributeNS(
      null,
      'viewBox',
      '0 0 ' + boxWidth + ' ' + boxHeight,
    );
    svgElem.setAttributeNS(null, 'class', 'h-6 w-6 mx-auto fill-current');
    svgElem.style.display = 'block';

    paths.forEach((path) => {
      const pathElem = document.createElementNS(xmlns, 'path');
      pathElem.setAttributeNS(null, 'd', path);
      svgElem.appendChild(pathElem);
    });

    const span = document.createElement('span');
    span.appendChild(svgElem);

    return span;
  }

  createLinkToDownloadFile(href: any, filename: string): void {
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getObservableValue(obs: Observable<any>): any {
    let value: any;
    obs.pipe(take(1)).subscribe((v) => (value = v));
    return value;
  }

  isImage(fileName: string): boolean {
    //@see https://regexper.com/?#%5C.%28jpg%7Cjpeg%7Cpng%7Cgif%7Csvg%7Ctiff%7Ctif%7Cwebp%7Cbmp%7Craw%29%24
    const imageRegex = new RegExp(
      '.(jpg|jpeg|png|gif|svg|tiff|tif|webp|bmp|raw)$',
    );

    return imageRegex.test(fileName);
  }

  isDocumentIconExist(docExtenstion: string): string {
    const docIconRegex = new RegExp(
      '(avi|css|csv|doc|html|json|mp3|mp4|pdf|ppt|xls|xml|zip)$',
    );

    const textDocRegex = new RegExp('(docx|odf|ott)$');
    const spreadsheetRegex = new RegExp('(xlsx|ods|sdc)$');
    const presentationRegex = new RegExp('(pptx|odg|sdd)$');

    if (docIconRegex.test(docExtenstion)) {
      return docExtenstion;
    } else if (textDocRegex.test(docExtenstion)) {
      return 'doc';
    } else if (spreadsheetRegex.test(docExtenstion)) {
      return 'xls';
    } else if (presentationRegex.test(docExtenstion)) {
      return 'ppt';
    } else {
      return 'undefined';
    }
  }

  getFileFromBackend(url: string, queryset?: Record<string, unknown>): void {
    this.http
      .post(url, queryset ?? {}, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(take(1))
      .subscribe((response: any) => {
        const filename = response.headers
          .get('content-disposition')
          .split('filename=')[1]
          .split(';')[0]
          .trim();

        saveAs(response.body, filename);
      });
  }

  sendProcessToBackend(url: string, queryset?: Record<string, unknown>): void {
    this.http.post(url, queryset ?? {}).subscribe();
  }
}
