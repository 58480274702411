import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { mapCompositionURL } from '@core/http/endpoints';

import { TableResponse } from '../models';
import { CompositionsResponse } from '../models/response-composition';

/** Service to get tables groups, apps, models */
@Injectable({
  providedIn: 'root',
})
export class CompositionService {
  /** @ignore */
  constructor(private http: HttpClient) {}

  /** http request to get Composition */
  getMapcompositions(): Observable<TableResponse<CompositionsResponse>> {
    return this.http
      .get<TableResponse<CompositionsResponse>>(mapCompositionURL)
      .pipe(
        map((resp) => {
          resp.results.unshift({ name: 'Default' });
          return resp;
        }),
      );
  }

  /** http request to save/add a Composition */
  saveMapcompositions(
    data: CompositionsResponse,
    compositionID?: number,
  ): Observable<CompositionsResponse> {
    if (compositionID) {
      return this.http.patch<CompositionsResponse>(
        `${mapCompositionURL}/${compositionID}`,
        data,
      );
    }
    return this.http.post<CompositionsResponse>(mapCompositionURL, data);
  }

  /** http request to delete a Composition */
  deleteMapcompositions(compositionID: number): Observable<null> {
    return this.http.delete<null>(`${mapCompositionURL}/${compositionID}`);
  }
}
