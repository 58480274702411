import { addUserIcon } from './add_user';
import { addOutlineIcon } from './add-outline';
import { alignCenterIcon } from './align-center';
import { alignJustifyIcon } from './align-justify';
import { alignLeftIcon } from './align-left';
import { alignRightIcon } from './align-right';
import { angleIcon } from './angle';
import { articleIcon } from './article';
import { assignmentIcon } from './assignment';
import { attachFileIcon } from './attach-file';
import { attachmentIcon } from './attachment';
import { booleanFalseIcon } from './boolean-false';
import { booleanTrueIcon } from './boolean-true';
import { cAccountBalanceIcon } from './c_account_balance';
import { cAgricultureIcon } from './c_agriculture';
import { cAnchorIcon } from './c_anchor';
import { cBuildIcon } from './c_build';
import { cCatchingPokemonIcon } from './c_catching_pokemon';
import { cCommuteIcon } from './c_commute';
import { cConstructionIcon } from './c_construction';
import { cDirectionsBoatIcon } from './c_directions_boat';
import { cElderlyIcon } from './c_elderly';
import { cEmojiObjectsIcon } from './c_emoji_objects';
import { cExploreIcon } from './c_explore';
import { cFlagIcon } from './c_flag';
import { cGroupsIcon } from './c_groups';
import { cHealthAndSafetyIcon } from './c_health_and_safety';
import { cHikingIcon } from './c_hiking';
import { cHomeIcon } from './c_home';
import { cLocalFloristIcon } from './c_local_florist';
import { cLocalShippingIcon } from './c_local_shipping';
import { cLocationCityIcon } from './c_location_city';
import { cMapIcon } from './c_map';
import { cMedicalServicesIcon } from './c_medical_services';
import { cOpacityIcon } from './c_opacity';
import { cParkIcon } from './c_park';
import { cPhotoCameraIcon } from './c_photo_camera';
import { cPublicIcon } from './c_public';
import { cRecyclingIcon } from './c_recycling';
import { cRestaurantIcon } from './c_restaurant';
import { cSchoolIcon } from './c_school';
import { cScienceIcon } from './c_science';
import { cSportsBasketballIcon } from './c_sports_basketball';
import { cSupportIcon } from './c_support';
import { cTourIcon } from './c_tour';
import { cTrafficIcon } from './c_traffic';
import { cWaterDropIcon } from './c_water_drop';
import { cWavesIcon } from './c_waves';
import { centerFocusIcon } from './center-focus';
import { checkmarkOutlineIcon } from './checkmark-outline';
import { cheveronDownIcon } from './cheveron-down';
import { cheveronLeftIcon } from './cheveron-left';
import { cheveronRightIcon } from './cheveron-right';
import { cheveronUpIcon } from './cheveron-up';
import { closeIcon } from './close';
import { closeOutlineIcon } from './close-outline';
import { commentIcon } from './comment';
import { composeIcon } from './compose';
import { cursorSelectIcon } from './cursor-select';
import { curveIcon } from './curve';
import { distortIcon } from './distort';
import { docAviIcon } from './doc-avi';
import { docCssIcon } from './doc-css';
import { docCsvIcon } from './doc-csv';
import { docDocIcon } from './doc-doc';
import { docHtmlIcon } from './doc-html';
import { docJsonIcon } from './doc-json';
import { docMp3Icon } from './doc-mp3';
import { docMp4Icon } from './doc-mp4';
import { docPdfIcon } from './doc-pdf';
import { docPptIcon } from './doc-ppt';
import { docTxtIcon } from './doc-txt';
import { docUndefinedIcon } from './doc-undefined';
import { docXlsIcon } from './doc-xls';
import { docXmlIcon } from './doc-xml';
import { docZipIcon } from './doc-zip';
import { doneIcon } from './done';
import { dotsThreeVerticalIcon } from './dots-three-vertical';
import { doubleChevronRightIcon } from './double-chevron-right';
import { downloadIcon } from './download';
import { dragIcon } from './drag';
import { editIcon } from './edit';
import { ellipsisIcon } from './ellipsis';
import { eventIcon } from './event';
import { eventsIcon } from './events';
import { filterIcon } from './filter';
import { filterConditionDoesnotcontainIcon } from './filter-condition-doesnotcontain';
import { filterConditionExactIcon } from './filter-condition-exact';
import { filterConditionGtIcon } from './filter-condition-gt';
import { filterConditionGteIcon } from './filter-condition-gte';
import { filterConditionIcontainsIcon } from './filter-condition-icontains';
import { filterConditionIendswithIcon } from './filter-condition-iendswith';
import { filterConditionIexactIcon } from './filter-condition-iexact';
import { filterConditionInIcon } from './filter-condition-in';
import { filterConditionIsnotnullIcon } from './filter-condition-isnotnull';
import { filterConditionIsnullIcon } from './filter-condition-isnull';
import { filterConditionIstartswithIcon } from './filter-condition-istartswith';
import { filterConditionLtIcon } from './filter-condition-lt';
import { filterConditionLteIcon } from './filter-condition-lte';
import { filterConditionNotequalIcon } from './filter-condition-notequal';
import { fitScreenIcon } from './fit-screen';
import { folderIcon } from './folder';
import { folderOpenIcon } from './folder-open';
import { formatBoldIcon } from './format-bold';
import { formatFontSizeIcon } from './format-font-size';
import { formatItalicIcon } from './format-italic';
import { formatUnderlineIcon } from './format-underline';
import { fullCloseIcon } from './full-close';
import { fullOpenIcon } from './full-open';
import { geomCircleIcon } from './geom-circle';
import { geomGeometryIcon } from './geom-geometry';
import { geomGeometrycollectionIcon } from './geom-geometrycollection';
import { geomLinestringIcon } from './geom-linestring';
import { geomMultiPointIcon } from './geom-multi-point';
import { geomMultilinestringIcon } from './geom-multilinestring';
import { geomMultipolygonIcon } from './geom-multipolygon';
import { geomPointIcon } from './geom-point';
import { geomPolygonIcon } from './geom-polygon';
import { geometryIcon } from './geometry';
import { importIcon } from './import';
import { informationIcon } from './information';
import { labelIcon } from './label';
import { layersIcon } from './layers';
import { linkIcon } from './link';
import { linkBrokenIcon } from './link-broken';
import { locationIcon } from './location';
import { locationCurrentIcon } from './location-current';
import { magnetIcon } from './magnet';
import { mapIcon } from './map';
import { mapPinSolidIcon } from './map-pin-solid';
import { minusIcon } from './minus';
import { multigeometryIcon } from './multigeometry';
import { multilineIcon } from './multiline';
import { multipointIcon } from './multipoint';
import { multipolygonIcon } from './multipolygon';
import { noteAddIcon } from './note-add';
import { opacityIcon } from './opacity';
import { plusIcon } from './plus';
import { printerIcon } from './printer';
import { questionIcon } from './question';
import { questionOutlineIcon } from './question-outline';
import { radiusIcon } from './radius';
import { redoIcon } from './redo';
import { reloadIcon } from './reload';
import { rulerIcon } from './ruler';
import { searchIcon } from './search';
import { searchOnMapIcon } from './search-on-map';
import { shareIcon } from './share';
import { shieldIcon } from './shield';
import { starIcon } from './star';
import { strokeCapButtIcon } from './stroke-cap-butt';
import { strokeCapRoundIcon } from './stroke-cap-round';
import { strokeCapSquareIcon } from './stroke-cap-square';
import { strokeDash1Icon } from './stroke-dash-1';
import { strokeDash2Icon } from './stroke-dash-2';
import { strokeDash3Icon } from './stroke-dash-3';
import { strokeJoinBevelIcon } from './stroke-join-bevel';
import { strokeJoinMitterIcon } from './stroke-join-mitter';
import { strokeJoinRoundIcon } from './stroke-join-round';
import { strokeSolidIcon } from './stroke-solid';
import { strokeWidthIcon } from './stroke-width';
import { tableIcon } from './table';
import { taskIcon } from './task';
import { textIcon } from './text';
import { translateIcon } from './translate';
import { trashIcon } from './trash';
import { undoIcon } from './undo';
import { userIcon } from './user';
import { verticalAlignBottomIcon } from './vertical-align-bottom';
import { verticalAlignCenterIcon } from './vertical-align-center';
import { verticalAlignTopIcon } from './vertical-align-top';
import { viewHideIcon } from './view-hide';
import { viewShowIcon } from './view-show';
import { zoomInIcon } from './zoom-in';
import { zoomToExtentIcon } from './zoom-to-extent';
import { zoomToRectangleIcon } from './zoom-to-rectangle';
export const svgIcons = [
  addOutlineIcon,
  addUserIcon,
  alignCenterIcon,
  alignJustifyIcon,
  alignLeftIcon,
  alignRightIcon,
  angleIcon,
  articleIcon,
  assignmentIcon,
  attachFileIcon,
  attachmentIcon,
  booleanFalseIcon,
  booleanTrueIcon,
  cAccountBalanceIcon,
  cAgricultureIcon,
  cAnchorIcon,
  cBuildIcon,
  cCatchingPokemonIcon,
  cCommuteIcon,
  cConstructionIcon,
  cDirectionsBoatIcon,
  cElderlyIcon,
  cEmojiObjectsIcon,
  cExploreIcon,
  cFlagIcon,
  cGroupsIcon,
  cHealthAndSafetyIcon,
  cHikingIcon,
  cHomeIcon,
  cLocalFloristIcon,
  cLocalShippingIcon,
  cLocationCityIcon,
  cMapIcon,
  cMedicalServicesIcon,
  cOpacityIcon,
  cParkIcon,
  cPhotoCameraIcon,
  cPublicIcon,
  cRecyclingIcon,
  cRestaurantIcon,
  cSchoolIcon,
  cScienceIcon,
  cSportsBasketballIcon,
  cSupportIcon,
  cTourIcon,
  cTrafficIcon,
  cWaterDropIcon,
  cWavesIcon,
  centerFocusIcon,
  checkmarkOutlineIcon,
  cheveronDownIcon,
  cheveronLeftIcon,
  cheveronRightIcon,
  cheveronUpIcon,
  closeOutlineIcon,
  closeIcon,
  commentIcon,
  composeIcon,
  cursorSelectIcon,
  curveIcon,
  distortIcon,
  docAviIcon,
  docCssIcon,
  docCsvIcon,
  docDocIcon,
  docHtmlIcon,
  docJsonIcon,
  docMp3Icon,
  docMp4Icon,
  docPdfIcon,
  docPptIcon,
  docTxtIcon,
  docUndefinedIcon,
  docXlsIcon,
  docXmlIcon,
  docZipIcon,
  doneIcon,
  dotsThreeVerticalIcon,
  doubleChevronRightIcon,
  downloadIcon,
  dragIcon,
  editIcon,
  ellipsisIcon,
  eventIcon,
  eventsIcon,
  filterConditionDoesnotcontainIcon,
  filterConditionExactIcon,
  filterConditionGtIcon,
  filterConditionGteIcon,
  filterConditionIcontainsIcon,
  filterConditionIendswithIcon,
  filterConditionIexactIcon,
  filterConditionInIcon,
  filterConditionIsnotnullIcon,
  filterConditionIsnullIcon,
  filterConditionIstartswithIcon,
  filterConditionLtIcon,
  filterConditionLteIcon,
  filterConditionNotequalIcon,
  filterIcon,
  fitScreenIcon,
  folderOpenIcon,
  folderIcon,
  formatBoldIcon,
  formatFontSizeIcon,
  formatItalicIcon,
  formatUnderlineIcon,
  fullCloseIcon,
  fullOpenIcon,
  geomCircleIcon,
  geomGeometryIcon,
  geomGeometrycollectionIcon,
  geomLinestringIcon,
  geomMultiPointIcon,
  geomMultilinestringIcon,
  geomMultipolygonIcon,
  geomPointIcon,
  geomPolygonIcon,
  geometryIcon,
  importIcon,
  informationIcon,
  labelIcon,
  layersIcon,
  linkBrokenIcon,
  linkIcon,
  locationCurrentIcon,
  locationIcon,
  magnetIcon,
  mapPinSolidIcon,
  mapIcon,
  minusIcon,
  multigeometryIcon,
  multilineIcon,
  multipointIcon,
  multipolygonIcon,
  noteAddIcon,
  opacityIcon,
  plusIcon,
  printerIcon,
  questionOutlineIcon,
  questionIcon,
  radiusIcon,
  redoIcon,
  reloadIcon,
  rulerIcon,
  searchOnMapIcon,
  searchIcon,
  shareIcon,
  shieldIcon,
  starIcon,
  strokeCapButtIcon,
  strokeCapRoundIcon,
  strokeCapSquareIcon,
  strokeDash1Icon,
  strokeDash2Icon,
  strokeDash3Icon,
  strokeJoinBevelIcon,
  strokeJoinMitterIcon,
  strokeJoinRoundIcon,
  strokeSolidIcon,
  strokeWidthIcon,
  tableIcon,
  taskIcon,
  textIcon,
  translateIcon,
  trashIcon,
  undoIcon,
  userIcon,
  verticalAlignBottomIcon,
  verticalAlignCenterIcon,
  verticalAlignTopIcon,
  viewHideIcon,
  viewShowIcon,
  zoomInIcon,
  zoomToExtentIcon,
  zoomToRectangleIcon,
];
