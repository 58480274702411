import { createReducer, on } from '@ngrx/store';

import { actionToggleHide } from './measurement.actions';

export const measurmentFeatureKey = 'measurement';

export interface MeasurementState {
  showAll: boolean;
}

const initialState: MeasurementState = {
  showAll: true,
};

export const measurementReducer = createReducer(
  initialState,
  on(
    actionToggleHide,
    (state): MeasurementState => ({
      ...state,
      showAll: !state.showAll,
    }),
  ),
);
