import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { myProfileURL } from '@core/http/endpoints';
import { ProfileResponse } from '@modules/profile';

import { actionAuthLogout } from '../store/auth.actions';
import { selectUserProfile } from '../store/session.selectors';

@Component({
  selector: 'gogis-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementModalComponent {
  profile$ = this.store.select(selectUserProfile);

  private destroyRef = inject(DestroyRef);

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  accept(): void {
    this.http
      .patch<ProfileResponse>(myProfileURL, { privatuma_politika: true })
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  refuse(): void {
    this.http
      .patch<ProfileResponse>(myProfileURL, { privatuma_politika: false })
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.store.dispatch(actionAuthLogout()));
  }
}
