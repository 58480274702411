import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectAPIAppByName } from '@core/api/api.selectors';
import { AppsResponse } from '@core/api/models';

@Pipe({
  name: 'getAppByName',
})
export class GetAppByNamePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(appName: string): Observable<AppsResponse> {
    return this.store.select(selectAPIAppByName(appName));
  }
}
