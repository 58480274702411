import { createReducer, on } from '@ngrx/store';

import Projection from 'ol/proj/Projection';

import * as objectActions from '@core/api/object.actions';
import { actionAddNewObject } from '@modules/map-panel/store/map-panel.actions';

import { actionSetContentTypes, actionSetProjection } from './map.actions';

export const mapFeatureKey = 'map';

export interface FeatureEdit {
  update: boolean;
}

export interface MapState {
  projection: Projection;
  featureEdit: FeatureEdit;
  contentTypes: number[];
}

const initialState: MapState = {
  projection: null,
  contentTypes: [],
  featureEdit: {
    update: null,
  },
};

export const mapReducer = createReducer(
  initialState,
  on(
    actionSetProjection,
    (state, { payload }): MapState => ({
      ...state,
      projection: payload,
    }),
  ),
  on(
    objectActions.actionObjectUpdateSuccess,
    objectActions.actionObjectAddSuccess,
    (state): MapState => ({
      ...state,
      featureEdit: { update: true },
    }),
  ),
  on(
    objectActions.actionObjectGetSuccess,
    actionAddNewObject,
    (state): MapState => ({
      ...state,
      featureEdit: { update: false },
    }),
  ),
  on(
    actionSetContentTypes,
    (state, { contentTypes }): MapState => ({
      ...state,
      contentTypes,
    }),
  ),
);
