import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { instanceSettingsURL } from '@core/http/endpoints';
import { LoadingState } from '@core/meta-reducers/call-state';

import { rowAdapter } from '../api.reducer';
import { SettingsResponse } from '../models';

/** Service to get instance settings */
@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  /** @ignore */
  constructor(private http: HttpClient) {}

  /** http request to get SettingsResponse */
  getInstanceSettings(composition?: string): Observable<SettingsResponse> {
    return this.http
      .get<SettingsResponse>(
        instanceSettingsURL + (composition ? `/${composition}` : ''),
      )
      .pipe(
        map((resp) => ({
          ...resp,
          layers: resp.map.groups.forEach((group) => {
            const layers = resp.map.layers.filter(
              (layer) => group.id === layer.group_id,
            );
            group.layers = layers;
          }),
          structure: {
            ...resp.structure,
            models: resp.structure.models.map((model) => ({
              ...model,
              rows: rowAdapter.getInitialState({
                callState: LoadingState.INIT,
              }),
            })),
          },
        })),
      );
  }
}
