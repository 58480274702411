<header class="relative z-50 flex flex-wrap items-center justify-between bg-gis-green-500 text-white shadow print:bg-white print:shadow-none">
  <a
    class="mx-4 flex flex-shrink-0 items-center text-white"
    role="banner"
    [routerLink]="(selectedComposition$ | async) ? '/map/' + (selectedComposition$ | async) : '/map'"
    title="{{ 'MAP.GO_TO' | translate }}"
  >
    <img id="LogoIdForPDF" alt="GoGIS" class="fill-current float-left my-1 h-10" [alt]="(instanceAttributes$ | async)?.title" [src]="instanceLogo" />
  </a>
  <div
    *ngIf="(selectedComposition$ | async) && (instanceAttributes$ | async)"
    class="mr-2 flex items-center rounded bg-gis-green-100 p-2 text-gis-grey-900 print:hidden"
    matTooltip="{{ 'MAP.COMPOSITION.TOOLTIP' | translate: { title: (selectedComposition$ | async) } }}"
  >
    <svg-icon *ngIf="(instanceAttributes$ | async).composition_icon" [key]="(instanceAttributes$ | async).composition_icon" />
    <p class="ml-1 inline-block font-Raleway first-letter:uppercase">
      {{ selectedComposition$ | async }}
    </p>
  </div>
  <div class="mr-5 block md:hidden print:hidden" role="button">
    <button
      class="text-teal-200 border-teal-400 flex items-center rounded border px-3 py-2 text-white hover:border-white hover:text-white"
      (click)="toggleNavigation()"
    >
      <svg class="h-3 w-3 fill-white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
    </button>
  </div>
  <div *ngIf="isLocalhostOrTestEnv" class="bg-blue-100 border-blue-500 text-blue-700 mr-1 border-b border-t px-2" role="alert">
    <p class="font-bold">{{ appName }}</p>
    <p class="text-sm">Version: {{ appVersion }}</p>
  </div>
  <nav class="w-full flex-grow pb-3 md:flex md:w-auto md:items-center md:pb-0 print:hidden" [class.hidden]="!isNavigationOpen">
    <ul class="md:flex-grow print:hidden">
      <li class="md:inline-block">
        <a
          routerLinkActive="bg-gis-green-600"
          class="block p-1 pl-5 hover:bg-gis-green-600 md:p-3"
          [routerLink]="'/map'"
          [queryParams]="{ composition: (selectedComposition$ | async) }"
          [routerLinkActiveOptions]="{ exact: true }"
          attr.aria-label="{{ 'MAP.GO_TO' | translate }}"
        >
          {{ "MAP.MAP" | translate }}
        </a>
      </li>
      <li class="md:inline-block">
        <a
          routerLink="/about"
          routerLinkActive="bg-gis-green-600"
          class="block p-1 pl-5 hover:bg-gis-green-600 md:p-3"
          data-cy="menu-link-about"
          [routerLinkActiveOptions]="{ exact: true }"
          attr.aria-label="{{ 'ABOUT.GO_TO' | translate }}"
        >
          {{ "ABOUT.ABOUT" | translate }}
        </a>
      </li>
      <li class="md:inline-block">
        <a class="block p-1 pl-5 hover:bg-gis-green-600 md:p-3" href="https://gis.sungis.lv/doc/" target="_blank" data-cy="menu-link-documentation">
          {{ "DOCUMENTATION.DOCUMENTATION" | translate }}
        </a>
      </li>
      <li class="md:inline-block">
        <a class="block p-1 pl-5 hover:bg-gis-green-600 md:p-3" target="_blank" data-cy="menu-link-admin" [href]="adminLink">
          {{ "GLOBAL.ADMINISTRATION" | translate }}
        </a>
      </li>
    </ul>

    <gogis-dropdown class="block pl-3 md:mr-4 md:pl-0 print:hidden" role="group" id="lang">
      <button
        gogisDropdownToggle
        class="flex items-center rounded px-2 py-1 hover:bg-gis-green-600 focus:outline-none"
        data-cy="language-dropdown"
        aria-haspopup="true"
      >
        {{ currentLang | uppercase }}
        <svg-icon key="cheveron-down" size="xs" />
      </button>
      <gogis-dropdown-container>
        <div class="mt-2 overflow-hidden rounded-b-md bg-white">
          <ng-container *ngFor="let lang of availableLangs">
            <a
              *ngIf="lang !== currentLang"
              gogisPreventDefaultHref
              href="#"
              class="block px-4 py-2 text-gis-grey-800 hover:bg-gis-green-100"
              attr.aria-label="{{ 'DOCUMENTATION.GO_TO' | translate }}"
              [attr.data-cy]="lang + '-button'"
              (click)="changeLanguage(lang)"
            >
              {{ lang | uppercase }}
            </a>
          </ng-container>
        </div>
      </gogis-dropdown-container>
    </gogis-dropdown>

    <gogis-dropdown
      *ngIf="((isAuthenticated$ | async) === false || null || undefined) && (instanceAttributes$ | async)?.features?.includes('authentication')"
      role="group"
      id="sign-in"
      class="print:hidden"
      [closeOnClickInside]="false"
    >
      <gogis-button
        gogisDropdownToggle
        class="ml-4 md:ml-0 md:mr-4"
        data-cy="sign-in-dropdown"
        label="{{ 'PROFILE.SIGN_IN' | translate }}"
        [size]="'sm'"
        [outline]="true"
      />
      <gogis-dropdown-container [dropright]="true">
        <div class="mt-2 w-full rounded-bl-md bg-white p-4 text-gis-grey-700 md:w-64">
          <form novalidate data-cy="sign-in-form" [formGroup]="authForm" (ngSubmit)="onLoginClick()">
            <label class="mb-2 block text-sm font-bold" for="username">
              {{ "PROFILE.USERNAME" | translate }}
            </label>
            <input formControlName="username" id="username" name="username" type="text" placeholder="{{ 'PROFILE.USERNAME' | translate }}" />
            <small class="text-gis-red-600" [hidden]="authForm.controls.username.valid || authForm.controls.username.untouched">
              {{ "PROFILE.USERNAME_REQUIRED" | translate }}
            </small>
            <label class="mb-2 mt-3 block text-sm font-bold" for="password">
              {{ "PROFILE.PASSWORD" | translate }}
            </label>
            <input formControlName="password" id="password" name="password" type="password" placeholder="**************" />
            <small class="text-gis-red-600" [hidden]="authForm.controls.password.valid || authForm.controls.password.untouched">
              {{ "PROFILE.PASSWORD_REQUIRED" | translate }}
            </small>
            <gogis-button
              class="mt-2 flex items-center"
              type="submit"
              size="sm"
              label="{{ 'PROFILE.SIGN_IN' | translate }}"
              [disabled]="authForm.invalid"
            />
          </form>
          <div class="mt-4 flex items-center justify-between space-x-2">
            <button
              *ngIf="(instanceAttributes$ | async)?.features?.includes('register')"
              class="cursor-pointer select-none text-xs underline hover:text-gis-grey-900"
              data-cy="register-button"
              (click)="openRegistrationDialog()"
            >
              {{ "PROFILE.REGISTRATION.TITLE" | translate }}
            </button>
            <button
              *ngIf="(instanceAttributes$ | async)?.features?.includes('forgotPassword')"
              class="cursor-pointer select-none text-xs underline hover:text-gis-grey-900"
              data-cy="reset-password-button"
              (click)="openPasswordReset()"
            >
              {{ "PROFILE.PASSWORD_RESET.TITLE" | translate }}
            </button>
          </div>
        </div>
      </gogis-dropdown-container>
    </gogis-dropdown>

    <gogis-dropdown *ngIf="isAuthenticated$ | async" class="block md:mr-4 print:hidden" role="group" id="profile">
      <ng-container *ngIf="myProfileData$ | async as myProfileData">
        <button gogisDropdownToggle class="ml-4 flex items-center focus:outline-none" data-cy="profile-dropdown">
          <ng-container *ngIf="myProfileData.avatar; else noAvatarTemplate">
            <gogis-secure-image class="h-10 w-10 rounded-full" alt="avatar" src="{{ myProfileData.avatar }}" />
          </ng-container>
          <ng-template #noAvatarTemplate>
            <svg-icon key="user" size="lg" class="overflow-hidden rounded-full text-gis-grey-400" />
          </ng-template>
          <svg-icon key="cheveron-down" size="xs" />
        </button>
        <gogis-dropdown-container [dropright]="true">
          <div class="mt-1 rounded-bl-md bg-white">
            <p class="userback-ignore block px-4 py-2 font-bold text-gis-grey-800">
              {{ myProfileData.nosaukums }}
            </p>

            <hr class="border-gis-grey-300" />
            <a
              href="#"
              routerLink="/my-profile"
              routerLinkActive="bg-gis-green-100"
              class="block px-4 py-2 text-gis-grey-800 hover:bg-gis-green-100"
              data-cy="profile-button"
              [routerLinkActiveOptions]="{ exact: true }"
              attr.aria-label="{{ 'PROFILE.GO_TO' | translate }}"
            >
              {{ "PROFILE.PROFILE" | translate }}
            </a>
            <a
              *ngIf="(instanceAttributes$ | async)?.features?.includes('issueRegistry') && (issuePermission$ | async)?.includes('view')"
              href="#"
              routerLink="/my-tasks"
              routerLinkActive="bg-gis-green-100"
              class="block px-4 py-2 text-gis-grey-800 hover:bg-gis-green-100"
              [routerLinkActiveOptions]="{ exact: true }"
              attr.aria-label="{{ 'PROFILE.GO_TO' | translate }}"
            >
              My tasks
              <!-- {{ "PROFILE.PROFILE" | translate }} -->
            </a>
            <a
              href="#"
              routerLink="/my-profile/settings"
              routerLinkActive="bg-gis-green-100"
              class="block px-4 py-2 text-gis-grey-800 hover:bg-gis-green-100"
              data-cy="profile-edit-button"
              [routerLinkActiveOptions]="{ exact: true }"
              attr.aria-label="{{ 'GLOBAL.GO_TO_SETTINGS' | translate }}"
            >
              {{ "GLOBAL.SETTINGS" | translate }}
            </a>

            <hr class="border-gis-grey-300" />
            <a
              gogisPreventDefaultHref
              href="#"
              class="block rounded-bl-md px-4 py-2 text-gis-grey-800 hover:bg-gis-green-100"
              data-cy="profile-logout-button"
              attr.aria-label="{{ 'PROFILE.SIGN_OUT' | translate }}"
              (click)="onLogoutClick()"
            >
              {{ "PROFILE.SIGN_OUT" | translate }}
            </a>
          </div>
        </gogis-dropdown-container>
      </ng-container>
    </gogis-dropdown>
  </nav>
  <div class="hidden text-gis-grey-800 print:block">
    {{ today }}
    <p *ngIf="myProfileData$ | async as profile">{{ profile.nosaukums }}</p>
  </div>
</header>
