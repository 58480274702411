import { createAction, props } from '@ngrx/store';

import Projection from 'ol/proj/Projection';

import { ProfileResponse } from '@modules/profile';

export const actionSetProjection = createAction(
  '[Map] Set projection',
  props<{ payload: Projection }>(),
);

export const actionOpenObjectFromMap = createAction(
  '[Map] 📑 Open object detail',
  props<{ objectId: string; modelId: number }>(),
);

export const actionSaveLayersPosition = createAction(
  '[Map] 💾 Auto-save layer position',
  props<{ groups: any[]; layers: any[] }>(),
);

export const actionSaveLayersPositionSuccess = createAction(
  '[Profile/API] ✅ Auto-save layer position Success',
  props<{ response: ProfileResponse }>(),
);

export const actionSaveLayersPositionFailure = createAction(
  '[Profile/API] ❌ Auto-save layer position Fail',
  props<{ error: string }>(),
);

// set visible layer content types if they exist
export const actionSetContentTypes = createAction(
  '[Layer-Switcher] Content types set',
  props<{ contentTypes: number[] }>(),
);

export const actionGetMapComposition = createAction(
  '[Map] Get map composition',
  props<{ compositionName: string }>(),
);
