<h2 matDialogTitle>Unauthorized</h2>
<mat-dialog-content>
  <p>You are not authorized to view this page. Please login in too see it</p>

  <form class="mt-2 w-64 rounded-bl-md bg-white text-gis-grey-700" novalidate [formGroup]="authForm">
    <label class="mb-2 block text-sm font-bold" for="username">
      {{ "PROFILE.USERNAME" | translate }}
    </label>
    <input formControlName="username" id="username" name="username" type="text" placeholder="{{ 'PROFILE.USERNAME' | translate }}" />
    <small class="text-gis-red-600" [hidden]="authForm.controls.username.valid || authForm.controls.username.untouched">
      {{ "PROFILE.USERNAME_REQUIRED" | translate }}
    </small>
    <label class="mb-2 mt-3 block text-sm font-bold" for="password">
      {{ "PROFILE.PASSWORD" | translate }}
    </label>
    <input formControlName="password" id="password" name="password" type="password" placeholder="**************" />
    <small class="text-gis-red-600" [hidden]="authForm.controls.password.valid || authForm.controls.password.untouched">
      {{ "PROFILE.PASSWORD_REQUIRED" | translate }}
    </small>
    <div class="mt-4 flex items-center">
      <!-- <span class="ml-auto">
        <p
          *ngIf="(instanceAttributes$ | async)?.features.includes('register')"
          (click)="openRegistrationDialog()"
          class="text-xs underline cursor-pointer select-none hover:text-gis-grey-900"
        >
          {{ "PROFILE.REGISTRATION.TITLE" | translate }}
        </p>
        <p
          *ngIf="(instanceAttributes$ | async)?.features.includes('forgotPassword')"
          (click)="openPasswordReset()"
          class="text-xs underline cursor-pointer select-none hover:text-gis-grey-900"
        >
          {{ "PROFILE.PASSWORD_RESET.TITLE" | translate }}
        </p>
      </span> -->
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <gogis-button color="grey" mat-dialog-close class="ml-4" label="{{ 'GLOBAL.CANCEL' | translate }}" />
  <gogis-button class="ml-auto mr-4" label="{{ 'PROFILE.SIGN_IN' | translate }}" [disabled]="authForm.invalid" [mat-dialog-close]="authForm.value" />
</mat-dialog-actions>
