import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, concatMap, mergeMap, tap } from 'rxjs/operators';

import { actionTableInlineGet } from '@modules/map-panel/object-panel/object-panel.actions';
import * as mapPanelActions from '@modules/map-panel/store/map-panel.actions';
import { actionOpenTableFromProfile } from '@modules/profile';

import { actionGenericFailed } from './api.actions';
import { selectAPIModelById } from './api.selectors';
import { OptionsService } from './services/options.service';
import { TablesService } from './services/tables.service';
import * as tableActions from './table.actions';

/** tables effects to import in EffectsModule.forRoot() */
@Injectable()
export class TableEffects {
  /** Get Options or Data depending if options already exist */
  retrieveTable = createEffect(() =>
    this.actions$.pipe(
      ofType(
        mapPanelActions.actionTableGet,
        tableActions.actionTableGet,
        actionOpenTableFromProfile,
        actionTableInlineGet,
      ),
      concatLatestFrom((action) =>
        this.store.select(selectAPIModelById(action.contentType)),
      ),
      concatMap(([action, model]) =>
        !model?.fieldsets
          ? of(
              tableActions.actionTableGetOptions({
                contentType: action.contentType,
                searchQuery: action.searchQuery,
                inlineField: action.inlineField,
              }),
            )
          : of(
              tableActions.actionTableGetData({
                contentType: action.contentType,
                model,
                searchQuery: action.searchQuery,
                inlineField: action.inlineField,
              }),
            ).pipe(
              tap(() => {
                const objectId = (action as any).objectId?.toString();
                if (objectId) {
                  mapPanelActions.actionOpenObjectFromTable({
                    objectId,
                    modelId: action.contentType,
                  });
                }
              }),
            ),
      ),
    ),
  );

  openTable = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          mapPanelActions.actionTableGet,
          tableActions.actionTableGet,
          actionOpenTableFromProfile,
        ),
        tap((action) => {
          //Go back to map to reinitialize the table
          this.router.navigate(['/map'], { queryParamsHandling: 'preserve' });
          this.router.navigate(['/map'], {
            queryParams: {
              table: action.contentType,
              object: null,
              report: null,
            },
            queryParamsHandling: 'merge',
          });
        }),
      ),
    { dispatch: false },
  );

  retrieveOptions = createEffect(() =>
    this.actions$.pipe(
      ofType(tableActions.actionTableGetOptions, tableActions.actionGetOptions),
      concatMap((action) =>
        this.optionsService.getTableOptions(action.contentType).pipe(
          concatMap((payload) => {
            if (action.type === tableActions.actionTableGetOptions.type) {
              return from([
                tableActions.actionTableGetOptionsSuccess({
                  contentType: action.contentType,
                  model: payload,
                }),
                tableActions.actionTableGetData({
                  contentType: action.contentType,
                  model: payload,
                  searchQuery: action.searchQuery,
                  inlineField: action.inlineField,
                }),
              ]);
            } else {
              return of(
                tableActions.actionTableGetOptionsSuccess({
                  contentType: action.contentType,
                  model: payload,
                }),
              );
            }
          }),
          catchError((error) =>
            of(actionGenericFailed({ error, actionType: action.type })),
          ),
        ),
      ),
    ),
  );

  retrieveData = createEffect(() =>
    this.actions$.pipe(
      ofType(tableActions.actionTableGetData),
      concatLatestFrom((action) =>
        this.store.select(selectAPIModelById(action.contentType)),
      ),
      concatMap(([action, model]) =>
        this.tableService
          .getTableData(
            action.contentType,
            model,
            action.searchQuery,
            action.inlineField,
          )
          .pipe(
            concatMap((payload) => {
              //Check if table as geometries && a next table
              if (payload.next) {
                return from([
                  action.inlineField
                    ? tableActions.actionInlineTableGetDataSuccess({
                        contentType: action.contentType,
                        rows: payload.rows,
                        objectCount: payload.objectCount,
                      })
                    : tableActions.actionTableGetDataSuccess({
                        contentType: action.contentType,
                        rows: payload.rows,
                        objectCount: undefined,
                      }),
                  tableActions.actionTableGetGeometries({
                    contentType: action.contentType,
                    hasGeom: !!model.geometry,
                  }),
                ]);
              }
              return from([
                action.inlineField
                  ? tableActions.actionInlineTableGetDataSuccess({
                      contentType: action.contentType,
                      rows: payload.rows,
                      objectCount: payload.objectCount,
                    })
                  : tableActions.actionTableGetDataSuccess({
                      contentType: action.contentType,
                      rows: payload.rows,
                      objectCount: undefined,
                    }),
                tableActions.actionTableGetSuccess({
                  contentType: action.contentType,
                }),
              ]);
            }),
            catchError((error) =>
              of(actionGenericFailed({ error, actionType: action.type })),
            ),
          ),
      ),
    ),
  );

  retrieveAdditionalData = createEffect(() =>
    this.actions$.pipe(
      ofType(
        tableActions.actionTableOrdering,
        tableActions.actionTableLoadMoreData,
      ),
      concatLatestFrom((action) =>
        this.store.select(selectAPIModelById(action.contentType)),
      ),
      mergeMap(([action, model]) =>
        this.tableService
          .getTableAdditionalData(
            action.contentType,
            action.offset,
            action.orderParam,
            action.rows,
            model.columns,
          )
          .pipe(
            mergeMap((payload) =>
              of(
                tableActions.actionTableGetDataSuccess({
                  contentType: action.contentType,
                  rows: payload.rows,
                  filterCount: payload.count,
                }),
              ),
            ),
            catchError((error) =>
              of(actionGenericFailed({ error, actionType: action.type })),
            ),
          ),
      ),
    ),
  );

  // filterData = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(tableActions.actionTableFiltering),
  //     concatLatestFrom((action) =>
  //       this.store.select(selectAPIModelById(action.contentType)),
  //     ),
  //     mergeMap(([action, model]) =>
  //       this.tableService
  //         .getTableAdditionalData(
  //           action.contentType,
  //           action.offset,
  //           action.orderParam,
  //           action.rows,
  //           model.columns,
  //         )
  //         .pipe(
  //           mergeMap((payload) => {
  //             if (model.columns.find((c) => c.filterValue) && payload.next) {
  //               return from([
  //                 tableActions.actionTableGetDataSuccess({
  //                   contentType: action.contentType,
  //                   rows: payload.rows,
  //                   objectCount: payload.count,
  //                 }),
  //                 tableActions.actionTableGetGeometries({
  //                   contentType: action.contentType,
  //                   hasGeom: !!model.geometry,
  //                 }),
  //               ]);
  //             } else {
  //               return of(
  //                 tableActions.actionTableGetDataSuccess({
  //                   contentType: action.contentType,
  //                   rows: payload.rows,
  //                   filterCount: payload.count,
  //                 }),
  //               );
  //             }
  //           }),
  //           catchError((error) =>
  //             of(actionGenericFailed({ error, actionType: action.type })),
  //           ),
  //         ),
  //     ),
  //   ),
  // );

  // retrieveGeometries = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(tableActions.actionTableGetGeometries),
  //     concatLatestFrom((action) =>
  //       this.store.select(selectAPIModelById(action.contentType)),
  //     ),
  //     switchMap(([action, model]) =>
  //       this.tableService
  //         .getTableGeometries(action.contentType, action.hasGeom, model.columns)
  //         .pipe(
  //           switchMap((payload) => {
  //             if (payload.next) {
  //               return of(
  //                 tableActions.actionTableGetGeometriesSuccess({
  //                   contentType: action.contentType,
  //                   rows: payload.results,
  //                 }),
  //               );
  //             }
  //             return from([
  //               tableActions.actionTableGetGeometriesSuccess({
  //                 contentType: action.contentType,
  //                 rows: payload.results,
  //               }),
  //               tableActions.actionTableGetSuccess({
  //                 contentType: action.contentType,
  //               }),
  //             ]);
  //           }),
  //           catchError((error) =>
  //             of(actionGenericFailed({ error, actionType: action.type })),
  //           ),
  //           takeUntil(
  //             this.actions$.pipe(
  //               ofType(
  //                 mapPanelActions.actionTableClose,
  //                 mapPanelActions.actionTableGet,
  //                 tableActions.actionTableGet,
  //                 actionOpenTableFromProfile,
  //                 actionTableInlineGet,
  //                 actionPostNewObject,
  //                 mapPanelActions.actionOpenObjectFromTable,
  //                 actionOpenObjectFromMap,
  //                 actionOpenObjectFromSearch,
  //                 mapPanelActions.actionOpenObjectEditing,
  //                 actionOpenObjectFromProfile,
  //                 mapPanelActions.actionAddNewObject,
  //               ),
  //             ),
  //           ),
  //         ),
  //     ),
  //   ),
  // );

  /** @ignore */
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private tableService: TablesService,
    private optionsService: OptionsService,
  ) {}
}
