import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayName',
})
export class ObjectDisplayNamePipe implements PipeTransform {
  transform(value: any): string {
    return value?.object_title || value?.nosaukums || value?.id || null;
  }
}
