import {
  ChangeDetectionStrategy,
  Component,
  Host,
  Input,
  OnInit,
} from '@angular/core';

import { Feature, Map } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { SimplemapService } from '../simplemap.service';

/**
 * Geometry component: receive a geometry in WKT format
 *
 * @example
 * <gogis-simple-map id='map' class="flex w-64 h-64">
 * <gogis-simple-map-geometry></gogis-simple-map-geometry>
 * </gogis-simple-map>
 */
@Component({
  selector: 'gogis-simple-map-geometry',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeometryComponent implements OnInit {
  /** geometry string  */
  @Input() geometry?: string;

  private map: Map;

  private geometryLayer: VectorLayer<VectorSource<Feature<Geometry>>>;

  constructor(
    @Host()
    private simplemapService: SimplemapService,
  ) {}

  ngOnInit(): void {
    if (this.geometry) {
      this.map = this.simplemapService.map;
      // TODO create features seperatly, pass feature array to 'createGeometry' function
      // zoom to feature takes "this.feature, this.map" instead of "this.geometryLayer, this.map"
      this.geometryLayer = this.simplemapService.createVectorLayer(
        this.geometry,
      );
      this.map.addLayer(this.geometryLayer);
      this.simplemapService.zoomToGeometry(this.geometryLayer);
    }
  }
}
