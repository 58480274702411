import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AccordionDirective } from './accordion.directive';

/**
 * This component can be used to show expandable content.
 *
 * @example
 * <gogis-accordion-panel>
 *   <ng-container accordion-name></ng-container>
 *   <ng-container accordion-name-addon></ng-container>
 *   <ng-container accordion-content></ng-container>
 * </gogis-accordion-panel>
 */
@Component({
  selector: 'gogis-accordion-panel',
  templateUrl: './accordion-panel.component.html',
})
export class AccordionPanelComponent implements OnInit, OnChanges {
  /** Whether the accordion content is expanded  */
  @Input() isExpended = false;
  @Output() loadList = new EventEmitter<boolean>();

  /** Subscription to openAll/closeAll events. */
  openCloseAllSubscription$ = new EventEmitter<boolean>();

  private destroyRef = inject(DestroyRef);
  /** @ignore */
  constructor(public accordion: AccordionDirective) {}

  /** @ignore */
  ngOnInit(): void {
    if (this.accordion) {
      this.accordion.openCloseAllActions$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((expanded) => {
          this.isExpended = expanded;
        });
    }
  }

  ngOnChanges(): void {
    this.isExpended
      ? this.accordion.addExpanded()
      : this.accordion.removeExpanded();
  }

  /** Sets the expanded state of the panel to false. */
  close(): void {
    this.isExpended = false;
  }

  /** Sets the expanded state of the panel to true. */
  open(): void {
    this.isExpended = true;
  }

  /** Toggles the expanded state of the expansion panel. */
  toggle(): void {
    this.isExpended = !this.isExpended;
    this.loadList.emit(this.isExpended);
    this.isExpended
      ? this.accordion.addExpanded()
      : this.accordion.removeExpanded();
  }
}
