import { createAction, props } from '@ngrx/store';

import { ModelNormalize, ObjectResponse } from '@core/api/models';

export const actionObjectGet = createAction(
  '[Data/API] 💬 Load object',
  props<{ contentType: number; objectId: string }>(),
);
export const actionObjectGetOptions = createAction(
  '[Data/API] ℹ️ Get object options',
  props<{ contentType: number; objectId: string }>(),
);
export const actionObjectGetOptionsSuccess = createAction(
  '[Data/API] ℹ️ Get object options ✅',
  props<{ contentType: number; objectId: string; model: ModelNormalize }>(),
);
export const actionObjectGetData = createAction(
  '[Data/API] 📊 Get object data',
  props<{
    contentType: number;
    objectId: string;
    model?: ModelNormalize;
    modelName?: string;
    appName?: string;
  }>(),
);
export const actionObjectGetSuccess = createAction(
  '[Data/API] ✅ Load object success',
  props<{
    contentType: number;
    objectId: string;
    object: ObjectResponse;
    model?: ModelNormalize;
  }>(),
);

export const actionDeleteConfirmation = createAction(
  '[Confirmation Modal] 🗑 Delete object',
  props<{ objectId: string; modelId: number; isInline?: boolean }>(),
);

export const actionObjectDeleteSucces = createAction(
  '[Data/API] ✅ Delete object Success',
  props<{ contentType: number; objectId: string }>(),
);
export const actionObjectUpdateSuccess = createAction(
  '[Data/API] ✅ Update object Success',
  props<{ contentType: number; objectId: string; object: ObjectResponse }>(),
);
export const actionObjectAddSuccess = createAction(
  '[Data/API] ✅ Add object Success',
  props<{ contentType: number; objectId: string; object: ObjectResponse }>(),
);
export const actionObjectInlineAddSuccess = createAction(
  '[Data/API] ✅ Add object inline Success',
  props<{ contentType: number; objectId: string; object: ObjectResponse }>(),
);
export const actionObjectInlineDeleteSuccess = createAction(
  '[Data/API] ✅ Delete object inline Success',
  props<{ contentType: number; objectId: string }>(),
);
export const actionChoiceObjectAddSuccess = createAction(
  '[Data/API] ✅ Add choice object Success',
  props<{ contentType: number; objectId: string; object: ObjectResponse }>(),
);
