import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { selectAPISettings } from '@core/api/api.selectors';
import { selectUserPreferences } from '@core/auth';

@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {
  /** @ignore */
  constructor(private store: Store) {}

  /** intercept any http call done by the httpClient */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let language: string;
    this.store
      .select(selectUserPreferences)
      .pipe(
        filter((preference) => !!preference),
        take(1),
      )
      .subscribe((preference) => {
        language = preference.language;
      });

    this.store
      .select(selectAPISettings)
      .pipe(
        filter((settings) => !!settings),
        take(1),
      )
      .subscribe((settings) => {
        language = language || settings.language;
      });

    const requestToHandle = language
      ? request.clone({
          headers: request.headers.set('Accept-Language', `${language}`),
        })
      : request;

    return next.handle(requestToHandle);
  }
}
