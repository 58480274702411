import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LoaderService } from './loader.service';

@Component({
  selector: 'gogis-loader',
  template: `
    <div *ngIf="loading" class="absolute right-0 z-50 m-4 h-8 w-8">
      <gogis-red-box />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  loading: boolean = this.loaderService.isLoading$.value;

  private destroyRef = inject(DestroyRef);

  constructor(
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef,
  ) {
    this.ref.detach();
  }

  ngOnInit() {
    this.loaderService.isLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.loading = value;
        this.ref.detectChanges();
      });
  }
}
