import { Injectable, NgZone } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { filter, take } from 'rxjs/operators';

import { Feature, Overlay } from 'ol';
import { MousePosition } from 'ol/control';
import { toStringXY } from 'ol/coordinate';
import { intersects } from 'ol/extent';
import WKT from 'ol/format/WKT';
import { LineString, Point, Polygon } from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import { DoubleClickZoom, Interaction, Select } from 'ol/interaction';
import Extent from 'ol/interaction/Extent';
import { Image as ImageLayer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import Layer from 'ol/layer/Layer';
import VectorImageLayer from 'ol/layer/VectorImage';
import Map from 'ol/Map';
import { ImageWMS } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';

import { ObjectResponse } from '@core/api/models';
import { HelperService } from '@core/helper.service';
import { MapStyleService } from '@modules/map/map-style.service';
import { ProfilePreferences } from '@modules/profile';

import { MapService } from './map.service';
import { actionSetProjection } from './store/map.actions';

/**
 * Used to add general function for the map
 * note: if the service become to big split it in smaller service like map-style.service
 */
@Injectable({
  providedIn: 'root',
})
export class MapUtilsService {
  map: Map;

  /** Array of removed interactions */
  removedInteractions: Interaction[] = [];

  //Add a seperate highlightLayer to avoid redrawing the original. Improves performance. (https://github.com/openlayers/openlayers/issues/8514)
  highlightLayer = new VectorImageLayer({
    zIndex: 10,
  });

  constructor(
    private zone: NgZone,
    private styleService: MapStyleService,
    private mapService: MapService,
    private mapStyleService: MapStyleService,
    private translate: TranslateService,
    private store: Store,
    private helperSerevice: HelperService,
    private toastr: ToastrService,
  ) {
    this.mapService
      .getMap()
      .pipe(filter((map) => !!map))
      .subscribe((map) => {
        this.map = map;
        this.highlightLayer.setMap(map);
      });
  }

  readFeature(geometry: string): Feature<Geometry> {
    const reader = new WKT();
    return reader.readFeature(geometry);
  }

  /** Zoom the view on a geometry's extent. Default Zoom: '20.904698157020494' equals to scale '1/300'
   *
   * @param extent: extent of the geometry
   * @param zoom: zoom level
   * @param skipInExtent: if set to true the view won't be zoomed if the extent is in the current view
   *  Run outside Angular change detection for performance.
   */
  zoomView(extent: Extent | any, zoom?: number, skipInExtent?: boolean): void {
    if (extent && isFinite(extent[0])) {
      this.zone.runOutsideAngular(() => {
        this.map.getView().getProjection().getExtent();

        if (
          !intersects(this.map.getView().calculateExtent(), extent) ||
          !skipInExtent
        ) {
          this.map.getView().fit(extent, {
            duration: 750,
            padding: [0, 200, 200, 200],
            maxZoom: zoom || 20.904698157020494,
          });
        } else {
          return;
        }
      });
    }
  }

  /** Remove interactions unnecessary for drawing */
  removeMapInteractions(): void {
    this.map.getInteractions().forEach((interaction) => {
      if (
        interaction instanceof DoubleClickZoom ||
        interaction instanceof Select
      ) {
        this.removedInteractions.push(interaction);
        interaction.setActive(false);
      }
    });
  }

  /** Add the features back */
  addMapInteractions(): void {
    if (this.removedInteractions.length > 0) {
      this.removedInteractions.forEach((interaction: any, index: number) => {
        interaction.setActive(true);
        this.removedInteractions.splice(index, 1);
      });
    }
  }

  /** Set a feature's style to highlightStyle */
  highlightFeature(hoveredFeature: Feature<Geometry>): void {
    if (hoveredFeature && !hoveredFeature.get('isOpen')) {
      const feature = hoveredFeature.clone();
      feature.setStyle(this.styleService.getHighlightStyle());
      this.highlightLayer.setSource(
        new VectorSource({
          features: [feature],
        }),
      );
    }
  }

  /** Set back features original style */
  removeHighlight(): void {
    const highlightedFeature = this.highlightLayer.getSource()?.getFeatures();
    if (
      highlightedFeature &&
      !highlightedFeature[0]?.getProperties().hidden &&
      !highlightedFeature[0]?.getProperties().isOpen
    ) {
      this.highlightLayer.getSource().clear();
    }
  }

  setFeatureDefaultStyle(feature: Feature<Geometry>): void {
    feature?.setStyle(feature.getProperties().defaultStyle);
  }

  createFeature(
    geometry: string,
    properties: { model_id: number; object_id: any; name?: string },
    style: Style | Style[],
  ): Feature<Geometry> {
    const feature = this.readFeature(geometry);
    feature.setStyle(style);
    // Combination of model id and object id to be sure to have a unique id;
    feature.setId(`${properties.model_id}-${properties.object_id}`);
    feature.setProperties({
      ...properties,
      defaultStyle: style,
    });

    return feature;
  }

  createFeatureList(
    objects: ObjectResponse[],
    modelId: number,
    color?: string,
  ) {
    const featureList: Feature<Geometry>[] = [];
    const style = this.mapStyleService.generateStyle(color, null, 50);

    objects.forEach((object) => {
      if (object.the_geom) {
        const feature = this.createFeature(
          object.the_geom,
          {
            model_id: modelId,
            object_id: object.id,
            name: object.object_title ?? object.nosaukums,
          },
          style,
        );
        featureList.push(feature);
      }
    });
    return featureList;
  }

  addFeaturesToLayer(
    layerSource: VectorSource<Feature>,
    features: Feature<Geometry>[],
  ): void {
    layerSource.addFeatures(features);
  }

  createVectorLayer(featureList: any): VectorImageLayer<VectorSource<Feature>> {
    let declutter = false;
    if (featureList.length > 10000) {
      declutter = true;
    }
    const vectorImageLayer = new VectorImageLayer({
      map: this.map,
      declutter,
      zIndex: 1,
      source: new VectorSource({
        features: featureList.flat(1),
      }),
      imageRatio: 3,
    });
    return vectorImageLayer;
  }

  /* return a number of visible layers in an array of layers */
  countVisible(layers: Layer<any, any>[]): number {
    let count = 0;
    layers.forEach((layer) => {
      if (layer.getVisible()) {
        ++count;
      }
    });
    return count;
  }

  findImportedGroup(): LayerGroup {
    return this.map
      ?.getLayers()
      .getArray()
      .find(
        (layer) =>
          layer.getProperties().name ===
          this.translate.instant('MAP.IMPORT.IMPORT_GROUP_NAME'),
      ) as any;
  }

  // Change control's projection
  changeProjection(projection: string): void {
    this.mapService
      .getMap()
      .pipe(
        take(1),
        filter((map) => !!map),
      )
      .subscribe((map) => {
        map.getControls().forEach((control) => {
          if (control instanceof MousePosition) {
            control.setProjection(projection);
            this.store.dispatch(
              actionSetProjection({
                payload: cloneDeep(control.getProjection()),
              }),
            );
          }
        });
      });
  }

  /**
   * Save map session every 15 seconds
   */

  loadMapSession(preferences: ProfilePreferences): void {
    this.mapService
      .getMap()
      .pipe(
        filter((map) => !!map),
        take(1),
      )
      .subscribe((map) => {
        const untypedLayers: any[] = map.getLayers().getArray();

        untypedLayers.forEach((group: LayerGroup) => {
          const savedGroup = preferences.groups.find(
            (prefGroup) => prefGroup.id === group.getProperties().id,
          );
          if (savedGroup) {
            group.setOpacity(savedGroup.opacity);
            group.setZIndex(savedGroup.priority);
            group.setVisible(savedGroup.visible);
            group.setProperties({ collapsed: savedGroup.collapsed });
          }

          group
            .getLayers()
            .getArray()
            .forEach((layer: BaseLayer) => {
              const savedLayer = preferences.layers.find(
                (prefLayer) => prefLayer.id === layer.getProperties().id,
              );

              if (savedLayer) {
                layer.setOpacity(savedLayer.opacity);
                layer.setZIndex(savedLayer.priority);
                layer.setVisible(savedLayer.visible);
              }
            });
        });

        const view = map.getView();
        view.animate({ center: preferences.center, zoom: preferences.zoom });
      });
  }

  //Adds wms from spatial projects, could also be changed to add wms from get capabilities
  addWMSLayerFromSpatialProjects(
    object: ObjectResponse,
    groupName: string,
  ): void {
    let layerGroup: LayerGroup = (
      this.map
        .getLayers()
        .getArray()
        .filter(
          (lGroup) => lGroup.getProperties().name === groupName,
        ) as unknown as LayerGroup
    )[0];
    if (!layerGroup) {
      layerGroup = new LayerGroup({
        visible: true,
        opacity: 1,
        zIndex: 1,
      });
      layerGroup.setProperties({
        name: groupName,
        collapsed: false,
        dontSave: true,
      });
      this.map.getLayers().push(layerGroup);
    }
    if (
      !layerGroup
        .getLayers()
        .getArray()
        .find((layer) => layer.getProperties().name === object.nosaukums)
    ) {
      const WMS = new ImageLayer({
        source: new ImageWMS({
          url: object.service_url as string,
          //all the spatial projects seem to have layer name as spatial_project
          params: { LAYERS: 'spatial_project', VERSION: '1.1.1' },
          projection: 'EPSG:' + object.srid,
        }),
      });
      WMS.setProperties({
        name: object.nosaukums,
      });
      // const token = this.helperSerevice.getObservableValue(
      //   this.store.select(selectTokenId),
      // );
      // if (token) {
      //   this.setCustomImageLoader(WMS.getSource(), token);
      // }
      layerGroup.getLayers().push(WMS);
      this.toastr.success(this.translate.instant('WMS_ADDED'));
    } else {
      this.toastr.warning(this.translate.instant('WMS_ALREADY_ADDED'));
    }
  }

  //   customLoader(tile, src, token): void {
  //   const xhr = new XMLHttpRequest();
  //   xhr.open('GET', src);
  //   xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  //   xhr.onload = () => {
  //     const data = 'data:image/png;base64,' + btoa(unescape(encodeURIComponent(this.responseText));
  //     tile.getImage().src = data;
  //   });
  //   xhr.send();
  // }

  // setCustomImageLoader(source: any, token: string): void {
  //   source.setImageLoadFunction((image: any, src: string) => {
  //     const xhr = new XMLHttpRequest();

  //     xhr.open('GET', src);
  //     xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  //     xhr.responseType = 'arraybuffer';

  //     xhr.onload = () => {
  //       const arrayBufferView = new Uint8Array(xhr.response);
  //       const blob = new Blob([arrayBufferView], { type: 'image/png' });
  //       const urlCreator = window.URL || window.webkitURL || URL;
  //       const imageUrl = urlCreator.createObjectURL(blob);
  //       image.getImage().src = imageUrl;
  //     };

  //     xhr.send();
  //   });
  // }

  // setCustomTileLoader(source: TileWMS, token: string): void {
  //   source.setTileLoadFunction((tile: any, src: string) => {
  //     const xhr = new XMLHttpRequest();

  //     xhr.open('GET', src);
  //     xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  //     xhr.responseType = 'arraybuffer';

  //     xhr.onload = () => {
  //       const arrayBufferView = new Uint8Array(xhr.response);
  //       const blob = new Blob([arrayBufferView], { type: 'image/png' });
  //       const urlCreator = window.URL || window.webkitURL || URL;
  //       const imageUrl = urlCreator.createObjectURL(blob);
  //       tile.getImage().src = imageUrl;
  //     };

  //     xhr.send();
  //   });
  // }

  getFormatedArea(polygon: Polygon): string {
    const area = Math.abs(polygon.getArea());
    if (area > 1000000) {
      return `${Math.round((area / 1000000) * 100) / 100} km²`;
    } else if (area > 10000) {
      return `${Math.round((area / 10000) * 100) / 100} ha`;
    } else {
      return `${Math.round(area * 100) / 100} m²`;
    }
  }

  getFormatedLength(lineString: LineString): string {
    const length = Math.abs(lineString.getLength());
    if (length > 100) {
      return Math.round(length) / 1000 + ' km';
    } else {
      return Math.round(length * 100) / 100 + ' m';
    }
  }

  createMeasureTooltip(measureTooltip?: Overlay): Overlay {
    if (measureTooltip?.getElement()) {
      measureTooltip
        .getElement()
        .parentNode.removeChild(measureTooltip.getElement());
    }
    const mewElement = document.createElement('div');
    mewElement.className = 'ol-tooltip ol-tooltip-measure';
    const positioning: any = 'bottom-center';
    return new Overlay({
      element: mewElement,
      offset: [0, -15],
      positioning,
      className: 'ol-unselectable',
      stopEvent: false,
      position: null,
    });
  }

  setMeasureTooltipContent(tooltip: Overlay, geometry: Geometry): void {
    if (geometry.getType() === 'Polygon') {
      tooltip.setPosition(
        (geometry as Polygon).getInteriorPoint().getCoordinates(),
      );
      tooltip.getElement().innerHTML = this.getFormatedArea(
        geometry as Polygon,
      );
    } else if (geometry.getType() === 'LineString') {
      tooltip.setPosition((geometry as Point).getLastCoordinate());
      tooltip.getElement().innerHTML = this.getFormatedLength(
        geometry as LineString,
      );
    } else if (geometry.getType() === 'Point') {
      const pointCoordinates = (geometry as Point).getCoordinates();
      tooltip.setPosition(pointCoordinates);

      const posX = toStringXY(pointCoordinates, 4).split(',')[0];
      const posY = toStringXY(pointCoordinates, 4).split(',')[1];

      tooltip.getElement().innerHTML = `${posX} <br>${posY}`;
    }
  }
}
