import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipModule,
} from '@angular/material/tooltip';

import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

import { AccordionModule } from './components/accordion/accordion.module';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { AddEditModalModule } from './components/add-edit-modal/add-edit-modal.module';
import { ButtonModule } from './components/button/button.module';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DropdownModule } from './components/dropdown/dropdown.module';
import { ObjectDisplayNamePipe } from './components/object-display-name/object-display-name.pipe';
import { RedBoxComponent } from './components/red-box/red-box.component';
import { SecureImageComponent } from './components/secure-image/secure-image.component';
import { SimplemapModule } from './components/simplemap/simplemap.module';
import { TaskAssignUserModalComponent } from './components/tasks/task-assign-user-modal.component';
import { UserSearchFilterPipe } from './components/tasks/userSearch.pipe';
import { ConnectFormDirective } from './directives/connect-form.directive';
import { InhertLinkDirective } from './directives/href.directive';
import { GetAPIModelByIdPipe } from './pipes/api-model-by-id.pipe';
import { GetAppByNamePipe } from './pipes/app-by-name.pipe';
import { CallbackPipe } from './pipes/filter.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { GetLayerInGroupPipe } from './pipes/layer-in-group.pipe';
import { NumberSpacePipe } from './pipes/number-space.pipe';
import { SelectOptionsTranslatePipe } from './pipes/select-options-tranlate.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

/**
 * SharedModule should import and re-export
 * components from 3rd party component libraries
 * together with re-usable local components
 */
@NgModule({
  declarations: [
    SecureImageComponent,
    InhertLinkDirective,
    ConnectFormDirective,
    RedBoxComponent,
    ObjectDisplayNamePipe,
    GetLayerInGroupPipe,
    SelectOptionsTranslatePipe,
    GroupByPipe,
    GetAPIModelByIdPipe,
    GetAppByNamePipe,
    UserSearchFilterPipe,
    TimeAgoPipe,
    CallbackPipe,
    GetAPIModelByIdPipe,
    NumberSpacePipe,
    GetAppByNamePipe,
    ConfirmationModalComponent,
    TaskAssignUserModalComponent,
  ],
  imports: [
    // vendor
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,
    SvgIconsModule,

    MatDatetimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    NgSelectModule,
    // local
    DropdownModule,
    AccordionModule,
    SimplemapModule,
    ButtonModule,
  ],
  exports: [
    // vendor
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,
    SvgIconsModule,

    MatDatetimepickerModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTooltipModule,
    NgSelectModule,
    // local
    DropdownModule,
    AccordionModule,
    ButtonModule,
    SecureImageComponent,
    InhertLinkDirective,
    ConnectFormDirective,
    RedBoxComponent,
    SimplemapModule,
    ObjectDisplayNamePipe,
    GetLayerInGroupPipe,
    GetAPIModelByIdPipe,
    GetAppByNamePipe,
    GroupByPipe,
    SelectOptionsTranslatePipe,
    CallbackPipe,
    TimeAgoPipe,
    ConfirmationModalComponent,
    TaskAssignUserModalComponent,
    GetAPIModelByIdPipe,
    GetAppByNamePipe,
    NumberSpacePipe,
  ],
  providers: [
    NgSelectConfig,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { touchGestures: 'auto' },
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd.MM.yyyy' },
    },
  ],
})
export class SharedModule {}
