import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { selectAPIModelByModelName } from '@core/api/api.selectors';
import { ObjectResponse } from '@core/api/models';

@Component({
  selector: 'gogis-confirmation-modal',
  template: `
    <h2 mat-dialog-title>{{ 'DELETE_MODAL.ARE_YOU_SURE' | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      {{ 'MAP.MAP_PANEL.DELETING_CONFIMRATION' | translate }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <gogis-button
        color="grey"
        mat-dialog-close
        class="mr-4"
        label="{{ 'GLOBAL.CANCEL' | translate }}"
      />
      <gogis-button
        color="red"
        label="{{ 'GLOBAL.DELETE' | translate }}"
        [mat-dialog-close]="{
          delete: true,
          modelId: (model$ | async)?.django_content_type
        }"
      />
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  model$ = this.store.select(
    selectAPIModelByModelName(this.data.appName, this.data.modelName),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      value: ObjectResponse;
      modelID?: number;
      modelName?: string;
      appName?: string;
    },
    private store: Store,
  ) {}
}
