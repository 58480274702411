import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import Bar from 'ol-ext/control/Bar';
import Button from 'ol-ext/control/Button';

import { SimplemapService } from '../simplemap.service';
/**
 * bar-button component: add button to ol-ext.control.bar
 * and display the bar on map.
 *
 * @example
 * <gogis-simple-map id='map' class="flex w-64 h-64">
 * <gogis-simple-map-bar-button [title]="'My button'" [className]="'button'" [innerHtml]="'B'"
 * (state)="buttonStateChanged($event)"></gogis-simple-map-bar-button>
 * </gogis-simple-map>
 */
@Component({
  selector: 'gogis-simple-map-bar-button',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarButtonComponent implements OnInit {
  @Output() state = new EventEmitter<boolean>();

  @Input() title: string;

  @Input() className: string;

  @Input() innerHtml: string;

  /** top, top-left, left, bottom-left, bottom, bottom-right, right, top-right */
  @Input() position?: string;

  private bar: Bar;

  private buttonState: boolean;

  constructor(private mapService: SimplemapService) {}

  ngOnInit(): void {
    this.buttonState = false;

    // create or get map control bar
    if (this.mapService.controlBar) {
      this.bar = this.mapService.controlBar;
    } else {
      this.mapService.controlBar = new Bar({ toggleOne: true });
      this.mapService.map.addControl(this.mapService.controlBar);
      this.bar = this.mapService.controlBar;
    }

    // add a custom push button with onClick function
    const hello = new Button({
      html: this.innerHtml,
      className: this.className,
      title: this.title,
      handleClick: () => {
        this.buttonState = !this.buttonState;
        this.state.emit(this.buttonState);
      },
    });
    this.bar.addControl(hello);

    // set position of control bar
    if (this.position) {
      this.bar.setPosition(this.position);
    }
  }
}
