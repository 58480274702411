import { createAction, props } from '@ngrx/store';

import { AboutResponse } from '@modules/about/response-about';

import { SettingsResponse } from './models';

/** SETTINGS INITIAL ACTION */
export const actionLoadInitialSettings = createAction(
  '[App init] 💬 Load initial settings',
);

export const actionSetMapComposition = createAction(
  '[App init] set composition',
  props<{ compositionName: string }>(),
);

export const actionUnautorizedComposition = createAction(
  '[App init] 🚫 Unauthorized composition',
);

export const actionAutorizationModalClose = createAction(
  '[Autorization Modal] Close',
);

export const actionSignInLoginModal = createAction(
  '[Login Modal] Sign In login modal',
);

export const actionInstanceSettingsGetSuccess = createAction(
  '[Settings/API] ✅ Load settings Success',
  props<{ payload: SettingsResponse }>(),
);

export const actionAboutGet = createAction('[About page] 💬 Get data');

export const actionAboutGetSuccess = createAction(
  '[About/API] ✅ Get about data success',
  props<{ payload: AboutResponse }>(),
);

export const actionGenericFailed = createAction(
  '[API] ❌ Generic failure',
  props<{ error: string; actionType: string }>(),
);

export const actionNone = createAction('[API] No action to dispatch');
