import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
} from '@angular/core';

/**
 * gogis design button
 *
 * @example
 * <gogis-button></gogis-button>
 */
@Component({
  selector: 'gogis-button',
  template: `
    <button
      class="focus:shadow-outline rounded shadow hover:shadow-xl focus:outline-none"
      [title]="title ? title : label"
      [type]="type"
      [disabled]="disabled"
    >
      {{ label }}
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, OnChanges {
  /**
   * the button type
   * defaut: 'button'
   */
  @Input() type: 'button' | 'submit' = 'button';

  /**
   * the button lable
   */
  @Input() label: string;

  /**
   * the button title, if empty equal label
   */
  @Input() title?: string;

  /**
   * indicates the size: extra small(xs), small(sm),  medium(md) or large(lg)
   * defaut: 'md'
   */
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';

  /**
   * indicates the color
   * defaut: 'green'
   */
  @Input() color: 'green' | 'grey' | 'red' | 'yellow' | 'blue' = 'green';

  /**
   * the button have outline style
   * defaut: false
   */
  @Input() outline = false;

  /**
   * button is in disable state
   * defaut: false
   */
  @Input() disabled = false;

  /**
   * Inject dependencies inside constructor
   *
   * @param el  access to native element
   * @param renderer  implement custom rendering
   */
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  /**
   * execute when component is initalized
   */
  ngOnInit(): void {
    this.initSize();
    this.initColor();
  }

  ngOnChanges(): void {
    if (this.disabled) {
      this.renderer.addClass(this.el.nativeElement.firstChild, 'opacity-50');
      this.renderer.addClass(
        this.el.nativeElement.firstChild,
        'cursor-not-allowed',
      );
    } else {
      this.renderer.removeClass(this.el.nativeElement.firstChild, 'opacity-50');
      this.renderer.removeClass(
        this.el.nativeElement.firstChild,
        'cursor-not-allowed',
      );
    }
  }

  /**
   * initalize the class for the size
   */
  initSize(): void {
    switch (this.size) {
      case 'xs':
        this.renderer.addClass(this.el.nativeElement.firstChild, 'px-5');
        this.renderer.addClass(this.el.nativeElement.firstChild, 'text-sm');
        break;
      case 'sm':
        this.renderer.addClass(this.el.nativeElement.firstChild, 'py-1');
        this.renderer.addClass(this.el.nativeElement.firstChild, 'px-3');
        break;
      case 'lg':
        this.renderer.addClass(this.el.nativeElement.firstChild, 'py-3');
        this.renderer.addClass(this.el.nativeElement.firstChild, 'px-6');
        this.renderer.addClass(this.el.nativeElement.firstChild, 'text-lg');
        break;
      case 'md':
      default:
        this.renderer.addClass(this.el.nativeElement.firstChild, 'py-2');
        this.renderer.addClass(this.el.nativeElement.firstChild, 'px-4');
    }
  }

  /**
   * initalize the class for the color
   */
  initColor(): void {
    let bg = 'bg-gis-green-500';
    let hoverBg = 'hover:bg-gis-green-700';
    let text = 'text-gis-green-800';
    let textHover = 'hover:bg-gis-green-100';

    switch (this.color) {
      case 'grey':
        bg = 'bg-gis-grey-500';
        hoverBg = 'hover:bg-gis-grey-700';
        text = 'text-gis-grey-800';
        textHover = 'hover:bg-gis-grey-100';
        break;
      case 'red':
        bg = 'bg-gis-red-500';
        hoverBg = 'hover:bg-gis-red-700';
        text = 'text-gis-red-500';
        textHover = 'hover:bg-gis-red-200';
        break;
      case 'yellow':
        bg = 'bg-gis-yellow-500';
        hoverBg = 'hover:bg-gis-yellow-700';
        text = 'text-gis-yellow-800';
        textHover = 'hover:bg-gis-yellow-100';
        break;
      case 'blue':
        bg = 'bg-gis-blue-500';
        hoverBg = 'hover:bg-gis-blue-700';
        text = 'text-gis-blue-800';
        textHover = 'hover:bg-gis-blue-100';
        break;
      default:
        bg = 'bg-gis-green-500';
        hoverBg = 'hover:bg-gis-green-700';
        text = 'text-gis-green-800';
        textHover = 'hover:bg-gis-green-100';
    }

    if (!this.outline) {
      this.renderer.addClass(this.el.nativeElement.firstChild, 'text-white');
      this.renderer.addClass(this.el.nativeElement.firstChild, bg);
      this.renderer.addClass(this.el.nativeElement.firstChild, hoverBg);
    } else {
      this.renderer.addClass(this.el.nativeElement.firstChild, 'bg-white');
      this.renderer.addClass(this.el.nativeElement.firstChild, 'border');
      this.renderer.addClass(this.el.nativeElement.firstChild, text);
      this.renderer.addClass(this.el.nativeElement.firstChild, textHover);
    }
  }
}
