import { Injectable } from '@angular/core';

import { Circle, Fill, Stroke, Style, Text } from 'ol/style';

@Injectable({
  providedIn: 'root',
})
export class MapStyleService {
  getInvisibleStyle(): Style {
    const fill = new Fill({
      color: `rgba(0,0,0,0)`,
    });
    const stroke = new Stroke({
      color: `rgba(0,0,0,0)`,
    });
    const style = new Style({
      image: new Circle({
        fill,
        stroke,
        radius: 5,
      }),
      fill,
      stroke,
    });
    return style;
  }

  getTextStyle(label: string, color?: string): Style {
    const fill = new Fill({
      color: color || 'black',
    });
    const stroke = new Stroke({
      color: 'white',
      width: 2,
    });
    const style = new Style({
      text: new Text({
        text: label,
        font: '16px liberationserif_b, sans-serif',
        fill,
        stroke,
        overflow: true,
        offsetY: -10,
      }),
    });
    return style;
  }

  getDrawStyle(): Style {
    const fill = new Fill({
      color: `rgba(255, 255, 255, .3)`,
    });
    const stroke = new Stroke({
      lineDash: [10, 15],
      color: `rgba(0,0,0,1)`,
      width: 2,
    });
    const circleStroke = new Stroke({
      color: `rgba(0,0,0,1)`,
      width: 2,
    });
    const style = new Style({
      zIndex: Infinity,
      image: new Circle({
        fill,
        stroke: circleStroke,
        radius: 5,
      }),
      fill,
      stroke,
    });
    return style;
  }

  /** Default select styles in openlayers, used when highlighting an item */
  getHighlightStyle(): Style[] {
    const white = [255, 255, 255, 1];
    const yellow = [255, 213, 0, 1];
    const width = 3;
    const styles = [
      new Style({
        stroke: new Stroke({
          color: white,
          width: width + 2,
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.5],
        }),
        image: new Circle({
          radius: width * 2,
          fill: new Fill({
            color: yellow,
          }),
          stroke: new Stroke({
            color: white,
            width: width / 2,
          }),
        }),
        zIndex: Infinity,
      }),
      new Style({
        stroke: new Stroke({
          color: yellow,
          width,
        }),
        zIndex: Infinity,
      }),
    ];
    return styles;
  }

  // eslint-disable-next-line max-lines-per-function
  generateStyle(
    color: string,
    strokeWidth?: number,
    zIndex?: number,
    text?: { label: string; subLabel?: string },
  ): Style[] {
    const fill = new Fill({
      color: `rgba(255, 255, 255, .3)`,
    });
    const circleFill = new Fill({
      color,
    });
    const stroke = new Stroke({
      color: `rgba(255, 255, 255, .5)`,
      width: strokeWidth || 5,
    });
    const circleStroke = new Stroke({
      color: `rgba(255, 255, 255, .5)`,
      width: 2,
    });
    const style = [
      new Style({
        zIndex: zIndex ?? Infinity,
        image: new Circle({
          fill: circleFill,
          stroke: circleStroke,
          radius: strokeWidth || 5,
        }),
        text: text?.label
          ? new Text({
              text: text?.label,
              font: '16px liberationserif_b, sans-serif',
              fill: new Fill({
                color: 'black',
              }),
              stroke: new Stroke({
                color: 'white',
                width: 2,
              }),
              overflow: true,
              offsetY: -10,
            })
          : undefined,
        fill,
        stroke,
      }),
      new Style({
        text: new Text({
          text: text?.subLabel,
          font: '12px liberationserif_b, sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          overflow: true,
          offsetY: 8,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color,
          width: strokeWidth || 3,
        }),
        zIndex: zIndex ?? Infinity,
      }),
    ];

    return style;
  }

  /** create a style with random colours */
  generateRandomStyle(id?: number): Style {
    const generatedColor = this.generateColours(id);
    const fill = new Fill({
      color: generatedColor.background,
    });
    const stroke = new Stroke({
      color: generatedColor.border,
      width: 2.5,
    });
    const style = new Style({
      image: new Circle({
        fill,
        stroke,
        radius: 5,
      }),
      fill,
      stroke,
    });
    return style;
  }

  removeTextFromStyle(style: Style[]): Style[] {
    const newStyle = style.map((s) => {
      const clonedStyle = s.clone();
      if (clonedStyle.getText()) {
        clonedStyle.getText().setText('');
      }
      return clonedStyle;
    });
    return newStyle;
  }

  private generateColours(id?: number): { background: string; border: string } {
    const array = [];
    for (let i = 0; i < 3; ++i) {
      array.push(Math.round(Math.random() * 255));
    }

    let color;
    if (id) {
      color = this.hexToRgb(this.idToColor(id + ''));
    }

    const colorArray = color || array;

    const backgroundColor = `rgba(${colorArray[0] + 20},${colorArray[1] + 20},${
      colorArray[2] + 20
    },0.3)`;
    const borderColor = `rgba(${colorArray[0] - 20},${colorArray[1] - 20},${
      colorArray[2] - 20
    },1)`;

    return { background: backgroundColor, border: borderColor };
  }

  private idToColor(id: string) {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + (Number(hash < 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = Math.abs(Math.sin(hash + i)) * 0xffff;
      color += Math.floor(value).toString(16);
    }
    return color;
  }
  private hexToRgb(hex: string): number[] {
    return hex
      .replace(/^#/, '')
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
  }
}
