import { createSelector } from '@ngrx/store';

import { selectAPIModelByModelName } from '@core/api/api.selectors';
import { selectRouterState, selectSessionState } from '@core/core.state';

/** selector to know if user is authenticated */
export const selectIsAuthenticated = createSelector(
  selectSessionState,
  (state) => (state.tokenid ? true : false),
);

/** selector to get token id */
export const selectTokenId = createSelector(
  selectSessionState,
  (state) => state.tokenid,
);

/** selector to get current profile attributes */
export const selectUserProfile = createSelector(
  selectSessionState,
  (state) => state.user,
);

/** selector to get current profile preferences */
export const selectUserPreferences = createSelector(
  selectSessionState,
  (state) => state.preferences,
);

/** selector to get current profile attributes */
export const selectUserPermissionTable = createSelector(
  selectSessionState,
  selectRouterState,
  (state, router) => {
    const modelId = router.state.root.queryParams.table;
    return state.permissions?.find((model) => model.model_id === +modelId)
      ?.allowed_actions;
  },
);

export const selectUserPermissionTableByName = (
  appName: string,
  modelName: string,
) =>
  createSelector(
    selectSessionState,
    selectAPIModelByModelName(appName, modelName),
    (state, selectModel) =>
      state.permissions?.find(
        (model) => model.model_id === selectModel?.django_content_type,
      )?.allowed_actions,
  );
