import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';

import { MapService } from '@modules/map/map.service';
import { selectSidebar } from '@modules/store/component.selectors';

import { actionCloseSidebar, actionToggleTool } from './sidebar.actions';

/** Instance effects to import in EffectsModule.forRoot() */
@Injectable()
export class SidebarEffects {
  map$ = this.mapService.getMap().pipe(
    filter((map) => !!map),
    take(1),
  );

  selectTool = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionToggleTool, actionCloseSidebar),
        concatLatestFrom(() => this.store.select(selectSidebar)),
        tap(() => {
          this.map$.pipe(take(1)).subscribe((map) => {
            // Need to set a timeout here oserwise map canvas do not resize
            setTimeout(() => {
              map.updateSize();
            }, 1);
          });
        }),
      ),
    { dispatch: false },
  );

  /** @ignore */
  constructor(
    private store: Store,
    private actions$: Actions,
    private mapService: MapService,
  ) {}
}
