import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccordionDirective } from './accordion.directive';
import { AccordionPanelComponent } from './accordion-panel.component';
import { AccordionToggleComponent } from './accordion-toggle.component';

@NgModule({
  declarations: [
    AccordionDirective,
    AccordionToggleComponent,
    AccordionPanelComponent,
  ],
  exports: [
    AccordionDirective,
    AccordionToggleComponent,
    AccordionPanelComponent,
  ],
  imports: [CommonModule],
})
export class AccordionModule {}
