import { createReducer, on } from '@ngrx/store';

import { AttachmentResponse, IssueResponse } from '@core/api/models';
import * as objectActions from '@core/api/object.actions';
import { actionOpenObjectFromMap } from '@modules/map/store/map.actions';
import {
  actionOpenObjectFromProfile,
  actionOpenTableFromProfile,
} from '@modules/profile';
import {
  actionOpenObjectFromSearch,
  actionTableGetWithResults,
} from '@modules/spatial-search/store/spatial-search.actions';

import {
  actionAddTempInline,
  actionCancelEditing,
  actionDeleteTempInline,
  actionEditObject,
  actionObjectNavigateBack,
} from '../object-panel/object-panel.actions';
import {
  actionAddNewObject,
  actionOpenObjectEditing,
  actionOpenObjectFromTable,
  actionOpenReportSuccess,
  actionTableClose,
  actionTableGet,
} from './map-panel.actions';

export const mapPanelFeatureKey = 'map-panel';

// eslint-disable-next-line no-shadow
export enum RowsData {
  GEOMETRY = 'GEOMETRY',
  DATA = 'DATA',
  RESULTS = 'RESULTS',
}
export interface MapPanelState {
  isEditing: boolean;
  isNew: boolean;
  isReport: string;
  rowsData: RowsData;
  next: string;
  tempAttachments: FormData[];
  tempIssues: TempIssue[];
}

export interface TempIssue {
  issue: IssueResponse;
  value: IssueResponse;
}

export interface TempAttachment {
  attachment: AttachmentResponse;
  formData: FormData;
}

const initialState: MapPanelState = {
  isEditing: false,
  isNew: false,
  isReport: null,
  rowsData: RowsData.DATA,
  next: null,
  tempAttachments: [],
  tempIssues: [],
};

export const mapPanelReducer = createReducer(
  initialState,
  on(
    actionObjectNavigateBack,
    objectActions.actionObjectUpdateSuccess,
    objectActions.actionObjectInlineAddSuccess,
    objectActions.actionObjectGetData,
    actionOpenObjectFromMap,
    actionOpenObjectFromTable,
    actionOpenObjectFromSearch,
    actionOpenObjectFromProfile,
    actionOpenTableFromProfile,
    actionCancelEditing,
    actionTableClose,
    actionTableGet,
    actionTableGetWithResults,
    (state): MapPanelState => ({
      ...state,
      isEditing: false,
      isNew: false,
      isReport: null,
      tempAttachments: initialState.tempAttachments,
    }),
  ),
  on(
    actionEditObject,
    actionAddNewObject,
    actionOpenObjectEditing,
    (state): MapPanelState => ({
      ...state,
      isEditing: true,
      isReport: null,
    }),
  ),
  on(
    actionAddNewObject,
    (state): MapPanelState => ({
      ...state,
      isNew: true,
    }),
  ),
  on(actionAddTempInline, (state, action) => ({
    ...state,
    tempAttachments: [
      ...state.tempAttachments.filter(
        (t) => t.get('tempId') !== action.data.get('tempId'),
      ),
      action.data,
    ],
  })),
  on(actionDeleteTempInline, (state, action) => ({
    ...state,
    tempAttachments: state.tempAttachments.filter(
      (t) => t.get('tempId') !== action.data.get('tempId'),
    ),
  })),
  on(
    actionTableGetWithResults,
    (state): MapPanelState => ({
      ...state,
      rowsData: RowsData.RESULTS,
    }),
  ),
  on(
    actionTableGet,
    (state): MapPanelState => ({
      ...state,
      rowsData: RowsData.DATA,
    }),
  ),
  on(
    actionOpenReportSuccess,
    (state, { payload }): MapPanelState => ({
      ...state,
      isReport: payload.metadata.name,
    }),
  ),
);
