<h2 mat-dialog-title>{{ "PROFILE.PASSWORD_RESET.TITLE" | translate }}</h2>
<form [formGroup]="passwordReset">
  <mat-dialog-content>
    <div>
      <label class="mt-2 font-bold" for="password">
        {{ "PROFILE.PASSWORD_RESET.PASSWORD" | translate }}
      </label>
      <input formControlName="password" placeholder="*********" type="password" name="password" id="password" required />
      <gogis-errors-input
        *ngIf="getControl('password').touched"
        [field]="{ label: 'PROFILE.PASSWORD_RESET.PASSWORD' | translate }"
        [errors]="getControl('password').errors"
      />
    </div>
    <div>
      <label class="mt-2 font-bold" for="confirmPassword">
        {{ "PROFILE.PASSWORD_RESET.CONFIRM_PASSWORD" | translate }}
      </label>
      <input formControlName="confirmPassword" placeholder="*********" type="password" name="confirmPassword" id="confirmPassword" required />
      <gogis-errors-input
        *ngIf="getControl('confirmPassword').touched"
        [field]="{ label: 'PROFILE.PASSWORD_RESET.CONFIRM_PASSWORD' | translate }"
        [errors]="getControl('confirmPassword').errors"
      />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <gogis-button color="grey" mat-dialog-close class="ml-4" label="{{ 'GLOBAL.CANCEL' | translate }}" />
    <gogis-button
      type="submit"
      color="green"
      class="ml-auto mr-4"
      label="{{ 'PROFILE.PASSWORD_RESET.CHANGE_PASSWORD' | translate }}"
      (click)="resetPassword()"
    />
  </mat-dialog-actions>
</form>
