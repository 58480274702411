import { createAction, props } from '@ngrx/store';

import { AppsResponse } from '@core/api/models';

/** Update the filter list (If app already exist in filter list keep the old state) */
export const actionUpdateFilterList = createAction(
  '[Layer Filter] 🔄 Update filter list',
  props<{ payload: AppsResponse[] }>(),
);

/** Expend/Collapse an app */
export const actionToggleExpend = createAction(
  '[Layer Filter] ↔️ Toggle Expend',
  props<{ payload: { appName: string } }>(),
);

/** Enable/Disable an app or a model */
export const actionToggleFilter = createAction(
  '[Layer Filter] ↔️ Toggle Filter',
  props<{ payload: { appName: string; modelId?: number } }>(),
);

/** Enable/Disable all apps */
export const actionToggleAllFilter = createAction(
  '[Layer Filter] ↔️ Toggle FilterAll',
);

/** Enable only active layer from map */
export const actionCheckActiveLayer = createAction(
  '[Layer Filter] Check active layer',
  props<{ payload: { modelsId: number[] } }>(),
);
