import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IssueResponse } from '@core/api/models';
import { CallState, LoadingState } from '@core/meta-reducers/call-state';

import {
  actionAddIssueSuccess,
  actionDeleteIssueSuccess,
  actionEditIssueSuccess,
  actionGetIssues,
  actionGetIssuesSuccess,
  actionSolveIssueSuccess,
} from './issue-list.actions';

export const issueListFeatureKey = 'issue-list';

export interface IssueListState extends EntityState<IssueResponse> {
  callState: CallState;
}

export const issueAdapter: EntityAdapter<IssueResponse> =
  createEntityAdapter<IssueResponse>({
    selectId: (issueResult: IssueResponse) => issueResult.id,
    sortComparer: false,
  });

const { selectAll } = issueAdapter.getSelectors();

export const initialState: IssueListState = issueAdapter.getInitialState({
  callState: LoadingState.INIT,
});

export const issueReducer = createReducer(
  initialState,
  on(
    actionGetIssues,
    (state): IssueListState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(actionGetIssuesSuccess, (state, { payload }) =>
    issueAdapter.setAll(payload, { ...state, callState: LoadingState.LOADED }),
  ),

  // on(actionAddTasksSuccess, (state, { payload }) =>
  //   issueAdapter.mapOne(
  //     {
  //       id: payload.issue,
  //       map: (entity) => ({
  //         ...entity,
  //         tasks: [...entity?.tasks, payload],
  //       }),
  //     },
  //     { ...state }
  //   )
  // ),
  // on(actionDeleteTaskSuccess, (state, { taskId, issueId }) =>
  //   issueAdapter.mapOne(
  //     {
  //       id: issueId,
  //       map: (entity) => ({
  //         ...entity,
  //         tasks: [...entity.tasks.filter((task) => task.id !== taskId)],
  //       }),
  //     },
  //     { ...state }
  //   )
  // ),
  // on(actionUpdateTaskSuccess, (state, { payload, issueId }) =>
  //   issueAdapter.mapOne(
  //     {
  //       id: issueId,
  //       map: (entity) => ({
  //         ...entity,
  //         tasks: [
  //           ...entity.tasks.map((task) => {
  //             if (+task.id === +payload.id) {
  //               return payload;
  //             }
  //             return task;
  //           }),
  //         ],
  //       }),
  //     },
  //     { ...state }
  //   )
  // ),
  on(actionSolveIssueSuccess, actionEditIssueSuccess, (state, { update }) =>
    issueAdapter.updateOne(update, state),
  ),
  on(actionAddIssueSuccess, (state, { entity }) =>
    issueAdapter.setAll([{ ...entity, tasks: [] }, ...selectAll(state)], {
      ...state,
    }),
  ),
  on(actionDeleteIssueSuccess, (state, { id }) =>
    issueAdapter.removeOne(id, state),
  ),
);
