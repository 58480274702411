import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { issueURL } from '@core/http/endpoints';

import { IssueResponse, TableResponse } from '../models';

/** Service to get tables groups, apps, models */
@Injectable({
  providedIn: 'root',
})
export class IssueService {
  /** @ignore */
  constructor(private http: HttpClient) {}

  /** http request to get list of issue for supervisors */
  getIssuesList(
    ordering?: string,
    filter?: any[],
  ): Observable<TableResponse<IssueResponse>> {
    let url = `${issueURL}?data_format=full&limit=100`;
    url += ordering ? '&ordering=' + ordering : '';
    if (filter) {
      url += this.createFilterParameters(filter);
    }

    return this.http.get<TableResponse<IssueResponse>>(url);
  }

  /** http request to get list of issue for user */
  getPersonalTodoIssuesList(
    userID: string,
  ): Observable<TableResponse<IssueResponse>> {
    return this.http.get<TableResponse<IssueResponse>>(
      `${issueURL}?data_format=full&limit=100&status=in_progress&assign_displayname=${userID}`,
    );
  }

  /** http request to add issue */
  addIssue(values: IssueResponse): Observable<IssueResponse> {
    return this.http.post<IssueResponse>(issueURL, values);
  }

  /** http request to add issue */
  deleteIssue(id: string): Observable<IssueResponse> {
    return this.http.delete<IssueResponse>(`${issueURL}/${id}`);
  }

  /** http request to update issue */
  udapteIssue(id: string, values: IssueResponse): Observable<IssueResponse> {
    return this.http.put<IssueResponse>(`${issueURL}/${id}`, values);
  }

  /** http request to solve issue */
  solveIssue(id: string, value: boolean): Observable<IssueResponse> {
    return this.http.patch<IssueResponse>(`${issueURL}/${id}`, {
      status: value === true ? 'solved' : 'in_progress',
    });
  }

  private createFilterParameters(filters: any[]): string {
    let parameter = '';
    filters?.forEach((filter) => {
      if (filter.values.length) {
        parameter += `&${filter.filter}__in=${filter.values}`;
      }
    });

    return parameter;
  }
}
