import { createAction, props } from '@ngrx/store';

import { Coordinate } from 'ol/coordinate';

import { InlineField } from '@modules/map-panel/object-panel/object-panel.actions';
import { SearchQuery } from '@modules/spatial-search/spatial-search';

import { Password, ProfileResponse } from '../';
import { ResetPassword, UserRegistration } from '../profile';
import { ProfilePreferences } from '../response-profile';

export const actionUpdateUserSettings = createAction(
  '[Profile settings page] 💬 Update main settings',
  props<{ form: FormData }>(),
);

export const actionUpdateUserSettingsSuccess = createAction(
  '[Profile/API] ✅ Update main settings Success',
  props<{ response: ProfileResponse }>(),
);

export const actionUpdateUserSettingsFailure = createAction(
  '[Profile/API] ❌ Update main settings Failure',
  props<{ error: string }>(),
);

export const actionUpdateProfilePreferences = createAction(
  '[Profile] Update Preferences',
  props<{ payload: ProfilePreferences }>(),
);

export const actionUpdateProfilePreferencesSuccess = createAction(
  '[Profile] Update preference Success',
  props<{ response: ProfileResponse }>(),
);

export const actionUpdateProfilePreferencesFailure = createAction(
  '[Profile] Update preference Failure',
  props<{ error: string }>(),
);

export const actionSaveMapSession = createAction(
  '[Profile] Save map session',
  props<{ payload: { zoom: number; center: Coordinate } }>(),
);

export const actionSaveMapSessionSuccess = createAction(
  '[Profile/API] ✅ Save map session success',
  props<{ response: ProfileResponse }>(),
);

export const actionSaveMapSessionFailure = createAction(
  '[Profile/API] ❌ Save map session failure',
  props<{ error: string }>(),
);

export const actionUpdatePassword = createAction(
  '[Profile settings page] 💬 Update password',
  props<{ form: Password }>(),
);
export const actionUpdatePasswordSuccess = createAction(
  '[Profile/API] ✅ Update password Success',
);
export const actionUpdatePasswordFailure = createAction(
  '[Profile/API] ❌ Update password Failure',
  props<{ error: string }>(),
);

export const actionRegisterUser = createAction(
  '[User registration] 💬 Register user',
  props<{ form: UserRegistration }>(),
);
export const actionRegisterUserSuccess = createAction(
  '[Registration/API] ✅ User registration success',
);
export const actionRegisterUserFailure = createAction(
  '[Registration/API] ❌ User registration failed',
  props<{ error: string }>(),
);

export const actionForgotPassword = createAction(
  '[Forgot password] 💬 Send recovery link',
  props<{ username: string }>(),
);
export const actionForgotPasswordSuccess = createAction(
  '[Forgot password/API] ✅ Rcovery link sent',
);
export const actionForgotPasswordFailure = createAction(
  '[Forgot password/API] ❌ Rcovery link failed to send',
  props<{ error: string }>(),
);

export const actionResetPassword = createAction(
  '[Password recovery] 💬 Send recovery link',
  props<{ form: ResetPassword }>(),
);
export const actionResetPasswordSuccess = createAction(
  '[Password recovery/API] ✅ Rcovery link sent',
);
export const actionResetPasswordFailure = createAction(
  '[Password recovery/API] ❌ Rcovery link failed to send',
  props<{ error: string }>(),
);

export const actionOpenObjectFromProfile = createAction(
  '[Profile] 📄 View object detail',
  props<{ objectId: string; modelId: number }>(),
);

export const actionOpenTableFromProfile = createAction(
  '[Profile] 📖 View table',
  props<{
    contentType: number;
    searchQuery?: SearchQuery;
    inlineField?: InlineField;
  }>(),
);
