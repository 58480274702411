import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

// import { MatomoTracker } from 'sinsunsan-ngx-matomo';
import {
  selectAPIMapComposition,
  selectAPISettings,
} from '@core/api/api.selectors';
import { ENTRYPOINT } from '@core/http/endpoints';
import { PasswordForgotComponent } from '@modules/profile/password-forgot/password-forgot.component';
import { PasswordResetComponent } from '@modules/profile/password-reset/password-reset.component';
import { RegistrationComponent } from '@modules/profile/registration/registration.component';

import packageJson from '../../../../package.json';
import { environment } from '../../../environments/environment';
import {
  actionAuthLogin,
  actionAuthLogout,
  selectIsAuthenticated,
  selectUserPermissionTableByName,
  selectUserProfile,
} from '../auth';
import { actionChangeLanguage } from './store/header.actions';
/**
 * header component:
 * creates top bar with instance logo, nav, language switcher & login.
 */
@Component({
  selector: 'gogis-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  /** observable of user is authenticated. */
  isAuthenticated$ = this.store.select(selectIsAuthenticated);

  /** observable of profile data. */
  myProfileData$ = this.store.select(selectUserProfile);

  /** observable of instance settings. */
  instanceAttributes$ = this.store.select(selectAPISettings);

  issuePermission$ = this.store.select(
    selectUserPermissionTableByName('issues', 'issue'),
  );

  /** composition Name. */
  selectedComposition$ = this.store.select(selectAPIMapComposition);

  adminLink = `${ENTRYPOINT}admin`;

  isLocalhostOrTestEnv = false;
  appVersion = packageJson.version;
  appName = 'Localhost';

  /** authentication form. */
  authForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  today = new Date().toLocaleDateString();

  /**
   * indicates if the navigation is shown.
   * default: false
   */
  isNavigationOpen = false;

  /** the current language. */
  currentLang = '';

  /** list of langs available. */
  availableLangs = this.translate.getLangs();

  // localy we do not have repository lvinstance (backend settings managment (logo lives there)), so we get the logo from assets
  instanceLogo = environment.production
    ? '/static/instance/logo.png'
    : '../assets/images/gogis_logo_black-min.png';

  private destroyRef = inject(DestroyRef);
  /** @ignore */
  constructor(
    private store: Store,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    // private matomoTracker: MatomoTracker,
  ) {
    this.translate.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe((event: LangChangeEvent) => {
        this.currentLang = event.lang;
      });
  }

  /** @ignore */
  ngOnInit(): void {
    this.checkPasswordRecovery();
    this.checkEnvironment();
  }

  /** toggle the navigation in mobile. */
  toggleNavigation(): void {
    this.isNavigationOpen = !this.isNavigationOpen;
  }

  /**
   * change the language
   *
   * @param lang  string that match a json file name in `i18n` folder
   */
  changeLanguage(lang: string): void {
    // this.matomoTracker.trackEvent('header', 'language', lang);

    this.store.dispatch(actionChangeLanguage({ payload: lang }));
  }

  /** Execute on form login submit. */
  onLoginClick(): void {
    const credentials = this.authForm.value;

    // this.matomoTracker.trackEvent('header', 'sign in');
    this.store.dispatch(actionAuthLogin({ credentials }));
  }

  /** Execute on link logout click.  */
  onLogoutClick(): void {
    // this.matomoTracker.trackEvent('header', 'sign out');
    this.store.dispatch(actionAuthLogout());
  }

  openRegistrationDialog(): void {
    this.dialog.open(RegistrationComponent, {
      minWidth: '320px',
      width: '600px',
    });
  }

  openPasswordReset(): void {
    this.dialog.open(PasswordForgotComponent, {
      minWidth: '320px',
      width: '400px',
    });
  }

  private checkPasswordRecovery(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        if (params?.recovery) {
          this.dialog.open(PasswordResetComponent, {
            data: params.recovery,
            minWidth: '320px',
            width: '400px',
          });
          this.router.navigate([], {
            queryParams: { recovery: null },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  private checkEnvironment(): void {
    const host = window.location.hostname;
    this.isLocalhostOrTestEnv =
      host.includes('localhost') ||
      host.includes('alpha.gogis') ||
      host.includes('beta.gogis');
    this.appName = host.includes('alpha.gogis')
      ? 'Alpha'
      : host.includes('beta.gogis')
        ? 'Beta'
        : 'Localhost';
  }
}
