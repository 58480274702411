import { createAction, props } from '@ngrx/store';

import { SearchQuery } from '@modules/spatial-search/spatial-search';

export interface InlineField {
  field: string;
  targetObject: string;
  typeField?: string;
  targetModelID?: number;
}

export const actionObjectNavigateBack = createAction(
  '[Object] ⬅️ back to table',
  props<{ toTable: boolean }>(),
);
export const actionEditObject = createAction('[Object] 📝 Edit object');
export const actionDeleteObject = createAction('[Object] 🗑 Delete object');

export const actionCancelEditing = createAction('[Form] ❎ Cancel editing');
export const actionUpdateObject = createAction(
  '[Form] 📨 Update object',
  props<{
    objectId: string;
    modelId: number;
    data: FormData;
    isInline?: boolean;
  }>(),
);

export const actionPostNewObject = createAction(
  '[Form] ➕ Add object',
  props<{ modelId: number; data: FormData; isInline?: boolean }>(),
);

export const actionPostNewChoiceObject = createAction(
  '[Form] ➕ Add choice object',
  props<{ modelId: number; data: FormData }>(),
);

export const actionTableInlineGet = createAction(
  '[Object] 📖 Load inline table',
  props<{
    contentType: number;
    inlineField: InlineField;
    searchQuery?: SearchQuery;
  }>(),
);

export const actionAddTempInline = createAction(
  '[Form] ➕ Add temporary inline object',
  props<{ data: FormData }>(),
);

export const actionDeleteTempInline = createAction(
  '[Form] ➕ Delete temporary inline object',
  props<{ data: FormData }>(),
);
