import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AttachmentResponse } from '@core/api/models';
import { attachmentURL } from '@core/http/endpoints';

@Injectable({
  providedIn: 'root',
})
export class ObjectAttachmentService {
  constructor(private http: HttpClient) {}

  deleteAttachment(attachmentID: string): Observable<any> {
    return this.http.delete<any>(`${attachmentURL}/${attachmentID}`);
  }

  addAttachment(formData: FormData): Observable<AttachmentResponse> {
    return this.http.post<any>(`${attachmentURL}`, formData);
  }

  editAttachment(
    formData: FormData,
    attachmentID: string,
  ): Observable<AttachmentResponse> {
    return this.http.put<any>(`${attachmentURL}/${attachmentID}`, formData);
  }

  getFileNameExtension(file: string): string {
    return file?.split(/\./g)[1];
  }

  getFileTypeExtension(file: string): string {
    return file?.split(/\//g)[1];
  }
}
