/**
 *  Create API ENTRYPOINT. In case of local development, set to 'http://127.0.0.1:8000'
 *
 * @example 'https://my-randominstance.sungis.lv/gis/'
 */
export const ENTRYPOINT = window?.location?.origin?.includes('localhost')
  ? 'http://localhost:8000/'
  : window.location.origin + '/gis/';

export const MVT_ENTRYPOINT = window?.location?.origin?.includes('localhost')
  ? 'http://127.0.0.1:6161/'
  : window.location.origin + '/mvt/';

/**
 * URL to send authentication credentials
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/obtain'
 */
export const tokenAuthURL = `${ENTRYPOINT}api/v3/auth/obtain`;

/**
 * URL to refresh access token
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/refresh'
 */
export const tokenRefreshURL = `${ENTRYPOINT}api/v3/auth/refresh`;

/**
 * URL to get my-profile wen user login
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/profile'
 */
export const myProfileURL = `${ENTRYPOINT}api/v3/auth/profile`;

/**
 * URL for registration
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/register'
 */
export const registrationURL = `${ENTRYPOINT}api/v3/auth/register`;

/**
 * URL to delete 'jti' cookie
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/delete'
 */
export const deleteCookieURL = `${ENTRYPOINT}api/v3/delete-jti`;

/**
 * URL to change my password
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/password/change'
 */
export const myPasswordURL = `${ENTRYPOINT}api/v3/auth/password/change`;

/**
 * URL for registration
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/password/forgot'
 */
export const forgotPasswordURL = `${ENTRYPOINT}api/v3/auth/password/forgot`;

/**
 * URL for password reset
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/auth/password/reset'
 */
export const resetPasswordURL = `${ENTRYPOINT}api/v3/auth/password/reset`;

/**
 * URL to get intance settings
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/settings'
 */
export const instanceSettingsURL = `${ENTRYPOINT}api/v3/settings`;

/**
 * URL to get app structure
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/structure'
 */
export const instanceFullstructureURL = `${ENTRYPOINT}api/v3/structure`;

/**
 * URL to get about page informations
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/about'
 */
export const aboutURL = `${ENTRYPOINT}api/v3/about`;

/**
 * URL to get data from a table or an object
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/data/{{app.name}}/{{model.name}}'
 */
export const dataURL = `${ENTRYPOINT}api/v3/data`;

/**
 * URL to get map composition
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/map-compositions'
 */
export const mapCompositionURL = `${dataURL}/weblayers/mapcomposition`;

/**
 * URL to get attachment from of an object
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/data/files/attachment'
 */
export const attachmentURL = `${dataURL}/files/attachment`;

/**
 * URL to post search requests for spatial-search
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/search'
 */
export const spatialSearchURL = `${ENTRYPOINT}api/v3/search`;
export const spatialSearchURLV4 = `${ENTRYPOINT}api/v4/search`;

/**
 * URL to get relation between object
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/related'
 */
export const relatedURL = `${ENTRYPOINT}api/v3/related`;

/**
 * URL to get issues
 *
 * @example 'https://my-randominstance.sungis.lv/gis/api/v3/issues/issue'
 */
export const issueURL = `${dataURL}/issues/issue`;
