import { createSelector } from '@ngrx/store';

import { selectComponentsState } from '@core/core.state';

import { ComponentsState } from './component.reducer';

export const selectProjectionState = createSelector(
  selectComponentsState,
  (state) => state.map.projection,
);

export const selectFeatureEdit = createSelector(
  selectComponentsState,
  (state: ComponentsState) => state.map.featureEdit,
);

export const selectContentTypes = createSelector(
  selectComponentsState,
  (state) => state.map.contentTypes,
);

export const selectSidebar = createSelector(
  selectComponentsState,
  (state) => state.sidebar,
);

export const selectHiddenState = createSelector(
  selectComponentsState,
  (state) => state.measurement?.showAll,
);

export const selectSearchState = createSelector(
  selectComponentsState,
  (state) => state.search,
);

export const selectSidebarState = createSelector(
  selectComponentsState,
  (state) => state.sidebar,
);

export const selectFilterState = createSelector(
  selectComponentsState,
  (state) => state['search-filter'],
);

export const selectIsEditing = createSelector(
  selectComponentsState,
  (state) => state['map-panel'].isEditing,
);

export const selectIsAddingNew = createSelector(
  selectComponentsState,
  (state) => state['map-panel'].isNew,
);

export const selectIsReport = createSelector(
  selectComponentsState,
  (state) => state['map-panel'].isReport,
);

export const selectMapPanel = createSelector(
  selectComponentsState,
  (state) => state['map-panel'],
);

export const selectCompositionList = createSelector(
  selectComponentsState,
  (state) => state['composition-list'],
);

export const selectSelectedComposition = createSelector(
  selectComponentsState,
  (state) =>
    state['composition-list'].compositions.find(
      (compo) =>
        compo.name === state['composition-list'].detailsOpen ||
        compo.name === state['composition-list'].editing,
    ),
);
