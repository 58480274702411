import { createAction, props } from '@ngrx/store';

import { ProfileResponse } from '@modules/profile';

// actionChangeProjection action
export const actionChangeProjection = createAction(
  '[Footer] Change user projection preference',
  props<{ projection: string }>(),
);

// actionChangeProjection action
export const actionChangeProjectionSuccess = createAction(
  '[Footer] Change user projection preference success',
  props<{ response: ProfileResponse }>(),
);

// actionChangeProjection action
export const actionChangeProjectionFailure = createAction(
  '[Footer] Change user projection preference failure',
  props<{ error: string }>(),
);
