import { createReducer, on } from '@ngrx/store';

import { AppsResponse } from '@core/api/models';

import {
  actionCheckActiveLayer,
  actionToggleAllFilter,
  actionToggleExpend,
  actionToggleFilter,
  actionUpdateFilterList,
} from './spatial-filter.actions';

export const filterFeatureKey = 'search-filter';

export interface SearchFilterState {
  filterAll: boolean;
  appsModels?: AppsResponse[];
}

const initialState: SearchFilterState = {
  filterAll: true,
};

export const searchFilterReducer = createReducer(
  initialState,
  on(actionUpdateFilterList, (state, { payload }) => ({
    ...state,
    appsModels: payload.map(
      (app) =>
        state.appsModels?.find((stateApp) => stateApp.name === app.name) || app,
    ),
  })),
  on(actionToggleFilter, (state, { payload }) => ({
    ...state,
    appsModels: state.appsModels.map((app) => {
      if (payload.appName === app.name) {
        if (payload.modelId) {
          let filterOut: boolean;
          const models = app.models.map((model) => {
            if (payload.modelId === model.django_content_type) {
              filterOut = !model.filterOut ? app.filterOut : !model.filterOut;
              return {
                ...model,
                filterOut: !model.filterOut,
              };
            } else {
              return model;
            }
          });
          return { ...app, models, filterOut };
        }
        const modelList = app.models.map((model) => ({
          ...model,
          filterOut: !app.filterOut,
        }));
        return { ...app, filterOut: !app.filterOut, models: modelList };
      }

      return app;
    }),
  })),
  on(actionToggleExpend, (state, { payload }) => ({
    ...state,
    appsModels: state.appsModels.map((app) => {
      if (payload.appName === app.name) {
        return (app = { ...app, isExpended: !app.isExpended });
      }

      return app;
    }),
  })),
  on(actionCheckActiveLayer, (state, { payload }) => ({
    ...state,
    appsModels: state.appsModels.map((app) => {
      let filterOutApp = true;
      const models = app.models.map((model) => {
        if (payload.modelsId.includes(model.django_content_type)) {
          filterOutApp = false;
          return { ...model, filterOut: false };
        } else {
          return { ...model, filterOut: true };
        }
      });

      return { ...app, models, filterOut: filterOutApp };
    }),
  })),
  on(actionToggleAllFilter, (state) => ({
    ...state,
    filterAll: !state.filterAll,
    appsModels: state.appsModels.map((app) => {
      const models = app.models.map((model) => ({
        ...model,
        filterOut: state.filterAll,
      }));
      return (app = { ...app, models, filterOut: state.filterAll });
    }),
  })),
);
