import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectAPIModelById } from '@core/api/api.selectors';
import { ModelNormalize } from '@core/api/models';

@Pipe({
  name: 'getAPIModelById',
})
export class GetAPIModelByIdPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(modelId: number): Observable<ModelNormalize> {
    return this.store.select(selectAPIModelById(modelId));
  }
}
