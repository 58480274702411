import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import {
  actionSaveLayersPosition,
  actionSaveLayersPositionFailure,
  actionSaveLayersPositionSuccess,
} from '@modules/map/store/map.actions';

import { ProfileResponse } from '..';
import { ProfileService } from '../profile.service';
import {
  actionForgotPassword,
  actionForgotPasswordFailure,
  actionForgotPasswordSuccess,
  actionRegisterUser,
  actionRegisterUserSuccess,
  actionResetPassword,
  actionResetPasswordSuccess,
  actionSaveMapSession,
  actionSaveMapSessionFailure,
  actionSaveMapSessionSuccess,
  actionUpdatePassword,
  actionUpdatePasswordFailure,
  actionUpdatePasswordSuccess,
  actionUpdateProfilePreferences,
  actionUpdateProfilePreferencesFailure,
  actionUpdateProfilePreferencesSuccess,
  actionUpdateUserSettings,
  actionUpdateUserSettingsFailure,
  actionUpdateUserSettingsSuccess,
} from './profile.actions';

/** Side effects for profile actions */
@Injectable()
export class ProfileEffects {
  /** Update password from profile settings */
  updatePassword = createEffect(() =>
    this.actions$.pipe(
      ofType(actionUpdatePassword),
      exhaustMap((action) =>
        this.profileService.savePassword(action.form).pipe(
          tap(() =>
            this.toastrService.success(
              this.translateService.instant('PROFILE.UPDATE_PASSWORD_SUCCESS'),
            ),
          ),
          map(() => actionUpdatePasswordSuccess()),
          catchError((error) => of(actionUpdatePasswordFailure({ error }))),
        ),
      ),
    ),
  );

  /** Update main settings from profile settings */
  updateSetting = createEffect(() =>
    this.actions$.pipe(
      ofType(actionUpdateUserSettings),
      exhaustMap((action) =>
        this.profileService.saveSettings(action.form).pipe(
          tap(() => {
            this.toastrService.success(
              this.translateService.instant('PROFILE.UPDATE_PROFILE_SUCCESS'),
            );
          }),
          map((response) => actionUpdateUserSettingsSuccess({ response })),
          catchError((error) => of(actionUpdateUserSettingsFailure({ error }))),
        ),
      ),
    ),
  );

  registerUser = createEffect(() =>
    this.actions$.pipe(
      ofType(actionRegisterUser),
      switchMap((action) =>
        this.profileService.registerUser(action.form).pipe(
          map((res) => {
            this.toastrService.success(res.detail);
            this.dialogService.closeAll();
            return actionRegisterUserSuccess();
          }),
          catchError((error) => of(actionUpdateUserSettingsFailure({ error }))),
        ),
      ),
    ),
  );

  sendPasswordRecoveryLink = createEffect(() =>
    this.actions$.pipe(
      ofType(actionForgotPassword),
      switchMap((action) =>
        this.profileService.sendPasswordRecoveryLink(action.username).pipe(
          map(() => {
            this.toastrService.success(
              this.translateService.instant('PROFILE.RECOVERY_SENT'),
            );
            this.dialogService.closeAll();
            return actionForgotPasswordSuccess();
          }),
          catchError((error) => of(actionForgotPasswordFailure({ error }))),
        ),
      ),
    ),
  );

  resetPassword = createEffect(() =>
    this.actions$.pipe(
      ofType(actionResetPassword),
      switchMap((action) =>
        this.profileService.resetPassword(action.form).pipe(
          map(() => {
            this.toastrService.success(
              this.translateService.instant('PROFILE.PASSWORD_RESET_SUCCESS'),
            );
            this.dialogService.closeAll();
            return actionResetPasswordSuccess();
          }),
          catchError((error) => of(actionForgotPasswordFailure({ error }))),
        ),
      ),
    ),
  );

  savePreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionUpdateProfilePreferences),
      exhaustMap((action) =>
        this.profileService.saveUserPreference(action.payload).pipe(
          map((response: ProfileResponse) => {
            this.toastrService.success(
              this.translateService.instant('PROFILE.UPDATE_PROFILE_SUCCESS'),
            );
            this.translateService.use(response.preferences.language);
            return actionUpdateProfilePreferencesSuccess({ response });
          }),
          catchError((error) =>
            of(actionUpdateProfilePreferencesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  saveMapSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionSaveMapSession),
      switchMap((action) =>
        this.profileService
          .saveMapSession(action.payload.zoom, action.payload.center)
          .pipe(
            map((response) => actionSaveMapSessionSuccess({ response })),
            catchError((error) => of(actionSaveMapSessionFailure({ error }))),
          ),
      ),
    ),
  );

  saveLayersPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionSaveLayersPosition),
      switchMap((action) =>
        this.profileService
          .saveLayersPosition(action.groups, action.layers)
          .pipe(
            map((response) => actionSaveLayersPositionSuccess({ response })),
            catchError((error) =>
              of(actionSaveLayersPositionFailure({ error })),
            ),
          ),
      ),
    ),
  );

  /** @ignore */
  constructor(
    private actions$: Actions,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private dialogService: MatDialog,
  ) {}
}
