import { createAction, props } from '@ngrx/store';

import { CompositionsResponse } from '@core/api/models/response-composition';

export const actionGetCompositions = createAction(
  '[Composition List] 💬 Load composotions',
);

export const actionGetCompositionsSuccess = createAction(
  '[Composition/API] ✅ Load composotions Success',
  props<{ payload: CompositionsResponse[] }>(),
);

export const actionSelectComposition = createAction(
  '[Composition List] 🌐 Select a compostion',
  props<{ compositionName: string }>(),
);

export const actionOpenCompositionDetail = createAction(
  '[Composition List] 📖 Open compostion detail',
  props<{ compositionName: string }>(),
);

export const actionCloseCompositionDetail = createAction(
  '[Composition List] 📕 Close compostion detail',
);

export const actionEditComposition = createAction(
  '[Composition List] 📝 Edit compostion detail',
  props<{ compositionName: string }>(),
);

export const actionAddComposition = createAction(
  '[Composition List] ➕ Add compostion',
);

export const actionSaveComposition = createAction(
  '[Composition List] 📨 Save compostion',
  props<{ data: CompositionsResponse; compositionId?: number }>(),
);

export const actionDeleteComposition = createAction(
  '[Composition List] 🗑 delete compostion',
  props<{ compositionId: number }>(),
);

export const actionCloseCompositionEditing = createAction(
  '[Composition List] 📕 Close/Cancel compostion editing',
);

export const actionSaveCompositionSuccess = createAction(
  '[Composition/API] ✅ Save composotion Success',
  props<{ payload: CompositionsResponse }>(),
);

export const actionDeleteCompositionSuccess = createAction(
  '[Composition/API] ✅ delete composotion Success',
  props<{ payload: CompositionsResponse }>(),
);
