// eslint-disable-next-line simple-import-sort/imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { switchMapTo, take, throttleTime } from 'rxjs/operators';

import {
  myProfileURL,
  tokenAuthURL,
  tokenRefreshURL,
} from '@core/http/endpoints';
import { ProfileResponse } from '@modules/profile';

import { AgreementModalComponent } from './agreement-modal/agreement-modal.component';
import { AuthCredentials, AuthResponse } from './auth';
import { actionAuthLogout, actionRefreshToken } from './store/auth.actions';

const jwtHelperService = new JwtHelperService();

/** Service to handle all authentication request */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  refreshTimer;

  /** @ignore */
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private store: Store,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  /** http request to send on login */
  login({ username, password }: AuthCredentials): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(tokenAuthURL, { username, password });
  }

  /** http request to send to refresh token */
  refresh(tokenRefresh: string): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(tokenRefreshURL, {
      token: tokenRefresh,
    });
  }

  /** Get current user profile */
  getMyProfile(): Observable<ProfileResponse> {
    return this.http.get<ProfileResponse>(myProfileURL);
  }

  refreshToken(tokenId: string): void {
    const decodedToken = jwtHelperService.decodeToken(tokenId);
    const timeToExpirationInMs = decodedToken.exp * 1000 - Date.now();

    this.refreshTimer = setTimeout(() => {
      this.store.dispatch(actionRefreshToken());
    }, timeToExpirationInMs);
  }

  clearRefreshTimer(): void {
    clearTimeout(this.refreshTimer);
  }

  startTimerUntillInactivity(tokenId: string): void {
    const decodedToken = jwtHelperService.decodeToken(tokenId);
    const timeToExpirationInMs = decodedToken.exp * 1000 - Date.now();
    const resetTimer$ = timer(timeToExpirationInMs);
    const allEvents$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'touchmove'),
    );

    allEvents$
      .pipe(throttleTime(250), switchMapTo(resetTimer$), take(1))
      .subscribe(() => {
        this.toastrService.error(
          this.translateService.instant('PROFILE.SIGN_OUT_INACTIVITY'),
          '',
          { disableTimeOut: true, closeButton: true },
        );
        this.store.dispatch(actionAuthLogout());
      });
  }

  askTermsAgreement(): void {
    this.dialog.open(AgreementModalComponent, {
      minWidth: '320px',
      width: '400px',
      disableClose: true,
    });
  }
}
