import { createAction, props } from '@ngrx/store';

import { AttachmentResponse } from '@core/api/models';
import { TempAttachment } from '@modules/map-panel/store/map-panel.reducer';

export const actionAddAttachment = createAction(
  '[Attachment Add/Edit Modal] ➕ Add attachment',
  props<{
    formData: FormData;
    tempAttachment?: AttachmentResponse;
    objectID?: string;
  }>(),
);

export const actionAddAttachmentSuccess = createAction(
  '[Attachment Add/Edit Modal] Attachment added',
  props<{
    payload: {
      attachment: AttachmentResponse;
      objectID: string;
      modelID: number;
    };
  }>(),
);

export const actionAddTemporaryAttachment = createAction(
  '[Attachment Add/Edit Modal] Add temporary attachment',
  props<{ tempAttachment: TempAttachment }>(),
);
