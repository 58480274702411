import { Pipe, PipeTransform } from '@angular/core';

import { LayerResponse } from '@core/api/models';

@Pipe({
  name: 'getLayerInGroup',
})
export class GetLayerInGroupPipe implements PipeTransform {
  transform(layers: LayerResponse[], groupId: string): any {
    return layers.filter((layer) => layer.group_id === groupId);
  }
}
