import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { actionDeleteConfirmation } from '@core/api/object.actions';
import { CallState, LoadingState } from '@core/meta-reducers/call-state';

import { SearchQuery, SearchResultResponse } from '../spatial-search';
import * as searchAction from './spatial-search.actions';

export const searchFeatureKey = 'search';
export interface SearchState extends EntityState<SearchResultResponse> {
  callState: CallState;
  query?: SearchQuery;
  filters?: any;
}
export const searchAdapter: EntityAdapter<SearchResultResponse> =
  createEntityAdapter<SearchResultResponse>({
    selectId: (modelResult: SearchResultResponse) => modelResult.model_id,
  });

// eslint-disable-next-line one-var
export const initialState: SearchState = searchAdapter.getInitialState({
  callState: LoadingState.INIT,
});

export const spatialSearchReducer = createReducer(
  initialState,
  on(searchAction.actionSearch, (state, { searchQuery }) =>
    searchAdapter.removeAll({
      ...state,
      callState: LoadingState.LOADING,
      query: searchQuery,
    }),
  ),
  on(searchAction.actionSearchSuccess, (state, { results }) =>
    searchAdapter.addMany(results, {
      ...state,
      callState: LoadingState.LOADED,
    }),
  ),
  on(searchAction.actionSearchFailure, (state, { error }) =>
    searchAdapter.removeAll({
      ...state,
      callState: { errorMsg: error },
      styles: null,
    }),
  ),
  on(searchAction.actionClearResults, (state) =>
    searchAdapter.removeAll({ ...state, callState: LoadingState.INIT }),
  ),
  on(
    searchAction.actionCancelSearch,
    (state): SearchState => ({
      ...state,
      callState: state.entities ? LoadingState.LOADED : LoadingState.INIT,
    }),
  ),
  on(searchAction.actionRemoveFromResult, (state, { id }) =>
    searchAdapter.removeOne(id, state),
  ),
  on(
    searchAction.actionChangeColor,
    searchAction.actionToggleModel,
    (state, { update }) => searchAdapter.updateOne(update, state),
  ),
  on(searchAction.actionToggleAllModel, (state, { updates }) =>
    searchAdapter.updateMany(updates, state),
  ),
  on(actionDeleteConfirmation, (state, { modelId, objectId }) =>
    searchAdapter.mapOne(
      {
        id: modelId,
        map: (entity) => ({
          ...entity,
          results: entity.results.filter((object) => object.id !== objectId),
        }),
      },
      { ...state },
    ),
  ),
);
