import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Map } from 'ol';

/** Used to set & get the map */
@Injectable({
  providedIn: 'root',
})
export class MapService {
  map$ = new BehaviorSubject<Map>(null);

  mapState: Map;

  /** When map is set, all subscribers are updated */
  setMap(map: Map): void {
    this.map$.next(map);
  }

  getMap(): Observable<Map> {
    return this.map$.asObservable();
  }

  /** Save the map when it is about to be destroyed */
  setMapState(map: Map): void {
    this.mapState = map;
  }

  getMapState(): Map {
    return this.mapState;
  }
}
