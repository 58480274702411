export interface ProfileResponse {
  attributes: ProfileAttributes;
  preferences: ProfilePreferences;
  permissions: ProfilePermissions[];
  activities: ProfileActivities[];
}

export interface ProfileAttributes {
  avatar: string;
  nosaukums: string;
  is_active: boolean;
  created: string;
  updated: string;
  tips: string;
  kods: string;
  works: string;
  telefons: string;
  email: string;
  adrese: string;
  notes: string;
  is_staff: boolean;
  privatuma_politika: boolean;
}

export interface ProfilePreferences {
  language?: any;
  center?: any[];
  zoom?: any;
  projection?: any;
  save_session?: any;
  subscribe_newsletter?: any;
  groups?: GroupLayerPreferences[];
  layers?: GroupLayerPreferences[];

  /** to remove */
  proj4text?: any;
}

export interface GroupLayerPreferences {
  id: string;
  collapsed: boolean;
  visible: boolean;
  priority: number;
  opacity: number;
}

export interface ProfilePermissions {
  model_id: number;
  allowed_actions: string[];
}

export interface ProfileActivities {
  object_id: string;
  model_id: number;
  action: string;
  time: string;
  verbose_name: string;
  object_title: string;
}
