import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { Field } from '@core/api/models';

@Component({
  selector: 'gogis-errors-input',
  templateUrl: './errors-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsInputComponent {
  @Input() errors: ValidationErrors | null;
  @Input() field: Field | { label: string };
}
